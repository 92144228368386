<app-header-four></app-header-four>
<div class="page-title-area page-title-contact">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content"></div>
            </div>
        </div>
    </div>
</div>

<div class="container" style="margin: 2%; width: 100%" onselectstart="return false" onpaste="return false;"
    onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false">
    <div style="width: 80%; margin: 0 auto">
        <div class="col-md-5 mx-auto" style="
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 5%;
            ">
            <div>
                <div class="input-group" style="width: 250%">
                    <input class="form-control border-end-0 border" type="search" value="search"
                        [formControl]="searchText" id="example-search-input" placeholder="Search" />
                    <span class="input-group-append">
                        <button class="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5"
                            type="button" style="
                                height: 50px;
                                border-bottom: 1px solid #dee2e6 !important;
                                border-radius: 0px;
                            ">
                            <i class="fa fa-search"></i>
                        </button>
                    </span>
                </div>
            </div>

            <div class="rock">
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">1</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class="single-events-box" scrollTracker (scrollingFinished)="onScrollingFinished()">
            <div class="events-box event-card-shadow" *ngFor="let item of userList">
                <div class="events-image" style="margin-top: 15px; text-align: center">
                    <div class="">
                        <img src="{{
                                item.photoNameWithPath
                                    ? item.photoNameWithPath
                                    : defaultProfileImg
                            }}" alt="image" class="profileImg" />
                    </div>
                </div>

                <div class="events-content">
                    <div class="content">
                        <div class="name name-list-margin">
                            <strong class="f-size"> Name: </strong><span>{{ item.fullName }}</span>
                        </div>
                        <div class="detail name-list-margin">
                            <strong class="f-size">Skills:</strong><span>{{ item.skills }}</span>
                            <strong class="f-size"> Industry:</strong><span>{{ item.industry }}</span>
                        </div>
                        <div class="add" style="margin-bottom: 30px">
                            <strong class="f-size">Address: </strong><span>
                                {{ item.address }} {{ item.city }}
                                {{ item.state }} {{ item.pinCode }}</span>
                        </div>

                        <button class="btn btn-outline-primary" (click)="getProfile(item)">
                            View Profile
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-5 mx-auto" style="display: flex; justify-content: flex-end; width: 100%">
                <div class="rock">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="col-md-5 mx-auto" style="display: flex; justify-content: flex-end; width: 100%">
            <div class="rock">
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">1</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>

    <div class="modal bd-example-modal-lg" tabindex="-1" role="dialog" *ngIf="displayStyle" style="display: block">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Profile</h4>
                    <button type="button" class="btn btn-danger" (click)="closePopup()">
                        Close
                    </button>
                </div>
                <div class="modal-body" onselectstart="return false" onpaste="return false;" onCopy="return false"
                    onCut="return false" onDrag="return false" onDrop="return false">
                    <app-user-profile [userProfileData]="userData"></app-user-profile>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="closePopup()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>