import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/_service/authentication.service";

@Component({
    selector: "app-header-four",
    templateUrl: "./header-four.component.html",
    styleUrls: ["./header-four.component.scss"],
})
export class HeaderFourComponent implements OnInit {
    disabledLogout: any = [];
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.disabledLogout = localStorage.getItem("userData");
        console.log(this.disabledLogout);
    }
    logIn() {
        this.router.navigateByUrl("/signin");
    }
    goToSignUp() {
        this.router.navigateByUrl("/signUpStep");
    }
    getMyProfile = () => {
        this.router.navigateByUrl("/user-profile");
    };
    logOut() {
        this.authenticationService.logout();
        this.router.navigate(["/signin"]);
    }
}
