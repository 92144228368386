<app-header-four></app-header-four>

<div class="page-title-area page-title-contact">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content"></div>
            </div>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Sign-Up Form</h2>
        </div>
        <div class="row">
            <form [formGroup]="loginForm" (ngSubmit)="signUp(loginForm)">
                <div class="col-md-12">
                    <div class="contact-form">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label for="firstName">First Name</label>
                                    <input type="text" class="form-control" id="firstName" placeholder="First Name"
                                        formControlName="firstName" />
                                    <span *ngIf="firstName && firstName.touched && firstName.invalid"
                                        style="color: red">Enter Your First Name</span>

                                    <!-- <div *ngIf="name.invalid && (name.dirty ||name.touched)" class="alert alert-danger">

                                        <div *ngIf="name.errors.required">
                                            Name is required.
                                        </div>
                                        <div *ngIf="name.errors.minlength">
                                            Name must be at least 4 characters long.
                                        </div>
                                        <div *ngIf="name.errors.forbiddenName">
                                            Name cannot be Bob.
                                        </div>
                                    </div> -->

                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label for="lastName">Last Name</label>
                                    <input type="text" id="lastName" class="form-control" placeholder="Last Name"
                                        formControlName="lastName" />
                                    <span *ngIf="lastName && lastName.touched && lastName.invalid"
                                        style="color: red">Enter Your
                                        Last Name</span>

                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label for="dob">D.O.B</label>
                                    <input type="date" id="dob" placeholder="Enter Date of Birth" class="form-control"
                                        formControlName="dob" />
                                    <span *ngIf="dob && dob.touched && dob.invalid" style="color: red">Enter Your
                                        D.O.B</span>

                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12" *ngIf="this.userType==3">
                                <div class="form-group mb-3">
                                    <label for="CompanyName">Company Name</label>
                                    <input type="text" id="CompanyName" placeholder="Enter Company Name"
                                        class="form-control" formControlName="CompanyName" />

                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12" *ngIf="this.userType==3">
                                <div class="form-group mb-3">
                                    <label for="gstNo">GST No</label>
                                    <input type="text" id="gstNo" placeholder="Enter GST No" class="form-control"
                                        formControlName="gstNo" />

                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12" *ngIf="this.userType==4">
                                <div class="form-group mb-3">
                                    <label for="dob">Skills</label>
                                    <ng-multiselect-dropdown [placeholder]="'Select your Skills'"
                                        [settings]="skillDropDownSettings" [data]="skillDropDownList"
                                        formControlName="Skills">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12" *ngIf="this.userType==4">
                                <div class="form-group mb-3">
                                    <label for="dob">Industry</label>
                                    <ng-multiselect-dropdown [placeholder]="'Select your Industry'"
                                        [settings]="industryDropDownSettings" [data]="industryDropDownList"
                                        formControlName="Industry">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label for="userName">UserName</label>
                                    <input type="text" id="userName" class="form-control" placeholder="Enter User Name"
                                        formControlName="userName" disabled />
                                    <span *ngIf="userName && userName.touched && userName.invalid" style="color: red">
                                        Enter your username</span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label for="password">Password</label>
                                    <input type="password" id="password" class="form-control"
                                        placeholder="Enter Password" formControlName="password" />
                                    <span *ngIf="password && password.touched && password.invalid"
                                        style="color: red">Enter Your
                                        Password</span>
                                </div>
                            </div>



                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3"><label for="mobileNo">Mobile No</label>
                                    <input type="number" id="mobileNo" class="form-control" placeholder="Mobile No"
                                        formControlName="number" maxlength="10" disabled />
                                    <span *ngIf="number && number.touched && number.invalid" style="color: red">Enter
                                        Your
                                        Mobile no</span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3"><label for="referralUserId">Referral User No</label>
                                    <input type="text" id="referralUserId" class="form-control"
                                        placeholder="Referral User No" formControlName="referralUserId"
                                        maxlength="10" />

                                </div>
                            </div>


                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label for="address">Address</label>
                                    <input id="address" class="form-control" cols="30" rows="6" placeholder="Address"
                                        formControlName="address" />
                                    <span *ngIf="address && address.touched && address.invalid" style="color: red">Enter
                                        Your
                                        Address</span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label for="country">Country</label>
                                    <input type="text" id="Country" class="form-control" placeholder="India"
                                        formControlName="country" disabled />
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3"><label for="state">State</label>
                                    <select class="form-select" aria-label="Example select with button addon"
                                        formControlName="state">
                                        <option value="" disabled>
                                            Select State
                                        </option>
                                        <option *ngFor="let item of stateList" value="{{ item.stateId }}">
                                            {{ item.stateName }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label for="inputGroupSelect04">City</label>
                                    <select class="form-select" id="inputGroupSelect04"
                                        aria-label="Example select with button addon" formControlName="city">
                                        <option value="">Select City</option>
                                        <option class="form-control" *ngFor="let item of cityList"
                                            value="{{ item.cityId }}">
                                            {{ item.cityName }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label for="Pin Code">Pincode</label>
                                    <input type="number" id="Pin Code" class="form-control" placeholder="Enter Pincode"
                                        formControlName="pinCode" />
                                    <span *ngIf="pinCode && pinCode.touched && pinCode.invalid" style="color: red">Enter
                                        Your
                                        Pincode</span>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="User Type"
                                        class="form-control"
                                        placeholder="User Type"
                                        formControlName="userType"
                                    />
                                </div>
                            </div> -->

                            <div class="col-lg-12 col-md-12 text-center">
                                <button type="submit" class="default-btn">
                                    Submit <span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </form>
        </div>
    </div>
</section>