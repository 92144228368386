import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CommonService {
    origin = window.location.origin + "/";
    apiUrl: string;
    domainUrl: string;
    private mockData = [];
    userMobileNo = new BehaviorSubject({ UserType: "", PhoneNo: "" });
    public readonly searchSubject = new Subject<string | undefined>();
    constructor() {
        switch (this.origin) {
            case "http://localhost:4200/": {
                // this.apiUrl = "http://localhost:46741/api/";
                this.apiUrl = "http://api.dev.workermandi.com/api/";
                //   this.apiUrl = "http://api.workermandi.com/api/";
                this.domainUrl = "http://backoffice.dev.workermandi.com/";
                break;
            }
            case "https://workermandi.com/": {
                this.apiUrl = "https://api.workermandi.com/api/";
                this.domainUrl = "https://workermandi.com/";
                break;
            }
            default: {
                this.apiUrl = "https://api.workermandi.com/api/";
                this.domainUrl = "https://workermandi.com/";
                break;
            }
        }
    }

    // following method used use to pass api url based on environment {dev,qa,prod}//
    GetHost() {
        return this.apiUrl;
    }
    getMoreData(skip: number, limit: number): Observable<any[]> {
        // Simulate API call delay
        return of(this.mockData.slice(skip, skip + limit));
    }
}
