import { Injectable } from "@angular/core";
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivate,
} from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    constructor(private _router: Router) {}
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        debugger;
        if (localStorage.getItem("userData")) {
            return true;
        }
        this._router.navigate(["/signin"]);
        return false;
    }
}
