<app-header-four></app-header-four>
<div class="page-title-area page-title-contact">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content"></div>
            </div>
        </div>
    </div>
</div>

<div class="container1" *ngIf="step == 0">
    <form [formGroup]="submitMobileForm" (ngSubmit)="enterMobileNumber(submitMobileForm)">
        <div class="main1">
            <h3>Enter Mobile Number</h3>
            <div>
                <input class="no" type="text" formControlName="mobileNo" />
            </div>
            <button class="btn2" type="submit" [disabled]="btnDisabled">
                <span *ngIf="btnDisabled" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                Submit
            </button>
            <!-- <button class="btn2" type="submit" [disabled]="btnDisabled">
                Submit
            </button> -->
        </div>
    </form>
</div>
<div class="container2" *ngIf="step == 1">
    <form [formGroup]="submitOtpForm" (ngSubmit)="verifyOTP(submitOtpForm)">
        <div class="main2">
            <h3>Verify OTP</h3>
            <div>
                <input class="txt" type="text" placeholder="Enter OTP" formControlName="otp" />
            </div>

            <button class="btn3" type="submit">Verify</button>
        </div>
    </form>
</div>


<div *ngIf="step == 2">
    <div class="main">
        <div class="box_style">

            <section class="contact-area ptb-100 container width_40">
                <div class="container">
                    <div class="section-title">
                        <h2>Franchise Form</h2>
                    </div>
                    <div class="row">
                        <form [formGroup]="franchiseForm" (ngSubmit)="submitFranchiseForm(franchiseForm)">
                            <div class="col-md-12">
                                <div class="contact-form">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-6">
                                            <div class="form-group">
                                                <label for="firstName">First Name</label>
                                                <input type="text" class="form-control" id="firstName"
                                                    placeholder="First Name" formControlName="firstName" />
                                                <span *ngIf="firstName && firstName.touched && firstName.invalid"
                                                    style="color: red">Enter Your First Name</span>



                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-6">
                                            <div class="form-group mb-3">
                                                <label for="lastName">Last Name</label>
                                                <input type="text" id="lastName" class="form-control"
                                                    placeholder="Last Name" formControlName="lastName" />
                                                <span *ngIf="lastName && lastName.touched && lastName.invalid"
                                                    style="color: red">Enter Your
                                                    Last Name</span>

                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group mb-3"><label for="Email">Email id</label>
                                                <input type="email" id="Email" class="form-control"
                                                    placeholder="Enter Mail id" formControlName="email" />
                                                <span *ngIf="email&& email.touched && email.invalid"
                                                    style="color: red">Enter
                                                    Enter Mail id
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group mb-3"><label for="mobileNo">Mobile No</label>
                                                <input type="number" id="mobileNo" class="form-control" readonly
                                                    placeholder="Mobile No" formControlName="phoneNo" maxlength="10" />
                                                <!-- <span *ngIf="phoneNo && phoneNo.touched && phoneNo.invalid" style="color: red">Enter
                                        Your
                                        Mobile No</span> -->
                                            </div>
                                        </div>



                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group mb-3">
                                                <label for="CurrentProfession">Current Profession</label>
                                                <input id="CurrentProfession" class="form-control" cols="30" rows="6"
                                                    placeholder="Current Profession"
                                                    formControlName="currentProfession" />
                                                <span
                                                    *ngIf="currentProfession && currentProfession.touched && currentProfession.invalid"
                                                    style="color: red">Enter
                                                    Current Profession</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group mb-3">
                                                <label for="Discription">Discription</label>
                                                <input id="Discription" class="form-control" cols="30" rows="6"
                                                    placeholder="why you want to do work"
                                                    formControlName="discription" />
                                                <span *ngIf="discription && discription.touched && discription.invalid"
                                                    style="color: red">Enter Discription
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group mb-3">
                                                <label for="howDidYouKnow">How Did You Know</label>
                                                <input id="howDidYouKnow" class="form-control" cols="30" rows="6"
                                                    placeholder="How Did You Know" formControlName="howDidYouKnow" />

                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group mb-3"><label for="state">State</label>
                                                <select class="form-select"
                                                    aria-label="Example select with button addon"
                                                    formControlName="state">
                                                    <option value="" disabled>
                                                        Select State
                                                    </option>
                                                    <option *ngFor="let item of stateList" value="{{ item.stateId }}">
                                                        {{ item.stateName }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group mb-3">
                                                <label for="inputGroupSelect04">City</label>
                                                <select class="form-select" id="inputGroupSelect04"
                                                    aria-label="Example select with button addon"
                                                    formControlName="city">
                                                    <option value="">Select City</option>
                                                    <option class="form-control" *ngFor="let item of cityList"
                                                        value="{{ item.cityId }}">
                                                        {{ item.cityName }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group mb-3">
                                                <label for="Pin Code">Pincode</label>
                                                <input type="number" id="Pin Code" class="form-control"
                                                    placeholder="Enter Pincode" formControlName="pinCode" />
                                                <span *ngIf="pinCode && pinCode.touched && pinCode.invalid"
                                                    style="color: red">Enter
                                                    Your
                                                    Pincode</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 text-center">
                                            <button type="submit" class="default-btn" (click)="redirectPage()">
                                                Submit <span></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <div class="box_style margin_top">
            <div class="container width_40">


                <div style="width: 30vw;">
                    <div
                        style="background-color: lightblue; width: 30vw; color: white; padding: 5px; padding-left: 10px;">
                        <marquee behavior="scroll" direction="right">Worker Mandi Highlight</marquee>
                    </div>
                    <div>
                        <ul class="list-group">
                            <li>India's First Mobile App here you will get any Skilled/Unskilled Workers & Jobs at your
                                nearest location.</li>
                            <li>Wider Expose of Job</li>
                            <li>Affordable Subscription</li>
                            <li>High Return on Investment</li>
                            <li>Faster Payment</li>
                        </ul>

                    </div>
                </div>
                <div style="width: 30vw; " class="margin_top">
                    <div
                        style="background-color: lightblue; width: 30vw; color: white; padding: 5px; padding-left: 10px;">
                        <marquee behavior="scroll" direction="right">For details Contact Ust</marquee>
                    </div>
                    <div style="text-align: center; border: 2px solid lightcoral">

                        <ul>
                            <li class="list-group-item">Phone No : 6262562707</li>
                            <li class="list-group-item">You can also email us at:care@workermandi.com</li>
                        </ul>


                    </div>
                </div>
                <div style="width: 30vw;" class="margin_top">
                    <div
                        style="background-color: lightslategray; width: 30vw; color: white; padding: 5px; padding-left: 10px;">
                        Testimonials</div>

                </div>
            </div>
        </div>
    </div>
</div>