import { Injectable, NgZone } from "@angular/core";
import { GenericCallService } from "../generic-call.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment.prod";
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    private destroy$ = new Subject<void>();
    isLogin = false;
    constructor(
        private http:HttpClient,
        private _httpService: GenericCallService,
        private ngZone: NgZone,
        private router: Router
    ) {
        if (this.loggedIn()) {
            this.isLogin = true;
        }
        this.lastAction(Date.now());
        this.check();
        this.initListener();
        this.initInterval();
    }
    login(value: any) {
        debugger;
        const payload = {
            Username: value.username ? value.username : value.UserName,
            Password: value.password ? value.password : value.Password,
        };
        try {
            return this._httpService
                .postMethodWithoutToken("AuthenticationWebApi/dologin", payload)
                .pipe(takeUntil(this.destroy$));
        } catch (error) {
            console.log(error);
        }
    }
    logout() {
        localStorage.removeItem("userData");
    }
    loggedIn(): boolean {
        return localStorage.getItem("userData") !== null;
    }
    getLastAction() {
        return localStorage.getItem("lastAction");
    }
    lastAction(value) {
        localStorage.setItem("lastAction", JSON.stringify(value));
    }
    initListener() {
        this.ngZone.runOutsideAngular(() => {
            document.body.addEventListener("click", () => this.reset());
        });
    }
    initInterval() {
        this.ngZone.runOutsideAngular(() => {
            setInterval(() => {
                this.check();
            }, 1000);
        });
    }
    reset() {
        this.lastAction(Date.now());
    }
    check() {
        const now = Date.now();
        const timeLeft = parseInt(this.getLastAction()) + 5 * 60 * 1000;
        const diff = timeLeft - now;
        const isTimeout = diff < 0;
        this.ngZone.run(() => {
            if (isTimeout && this.isLogin) {
                this.logout();
                localStorage.removeItem("lastAction");
                setTimeout(() => {
                    console.log(
                        "Your Session Expired due to longer Inactivity, Login Again To Continue"
                    );
                }, 1000);
                this.router.navigate(["/signin"]);
            }
           
        });
    }
    ourServices():Observable<any>{
        try {
          
       
             return    this.http.get<any>("https://api.workermandi.com/api/Dashboard/getEmployerDashboard")
        } catch (error) {
            console.log(error);
        }
    }
  
}
