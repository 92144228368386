<app-header-four></app-header-four>

<div class="page-title-area page-title-privacy">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <div class="content">
                        <h3>Privacy Policy</h3>
                        <h3>Last updated on: 15 March, 2023</h3>
                        <p>This privacy policy sets out how Worker Mandi uses and protects any information that you give
                            Worker Mandi when you use
                            this website.</p>
                        <p>Worker Mandi is committed to ensuring that your privacy is protected. Should we ask you to
                            provide certain information
                            by which you can be identified when using this website, and then you can be assured that it
                            will only be used in
                            accordance with this privacy statement.</p>
                        <p>Worker Mandi may change this policy from time to time by updating this page. You should check
                            this page from time to
                            time to ensure that you are happy with any changes.</p>
                    </div>
                </div>

            </div>
        </div>

        <div class="insurance-details-desc">
            <h3>1.We may collect the following information:</h3>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i> Name and job title</li>
                        <li><i class="fas fa-check"></i>Contact information including email address</li>
                        <li><i class="fas fa-check"></i>Demographic information such as postcode, preferences and
                            interests</li>
                        <li><i class="fas fa-check"></i>Other information relevant to customer surveys and/or offers
                        </li>
                    </ul>
                </div>
            </div>

            <h3>2.What we do with the information we gather:</h3>


            <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we
            have put in suitable measures. We require certain information to understand your needs and provide you with a better
            service, and in particular for the following reasons:</p>
            
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <ul class="features-list">
                            <li><i class="fas fa-check"></i>Internal record keeping.</li>
                            <li><i class="fas fa-check"></i>We may use the information to improve our products and services.</li>
                            <li><i class="fas fa-check"></i>We may periodically send promotional emails about new products, special offers or other information which we think
                            you may find interesting using the email address which you have provided.</li>
                            <li><i class="fas fa-check"></i>From time to time, we may also use your information to contact you for market research purposes. We may contact you
                            by email or phone. We may use the information to customise the website according to your interests.
                            </li>
                        </ul>
                    </div>
                </div>

        

            <h3>3.How we use cookies</h3>

            <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is
            added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web
            applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and
            dislikes by gathering and remembering information about your preferences.</p>

            <p>We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and
            improve our website in order to tailor it to customer needs. We only use this information for statistical analysis
            purposes, after which the data is removed from the system.</p>

            <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and
            which you do not. A cookie in no way gives us access to your computer or any personally identifiable information about
            you, other than the data you choose to share with us.</p>

            <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can
                usually modify
                your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage
                of the website.</p>


            <h3>4.Controlling your personal information</h3>

            <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>

            <div class="row">
                <div class="col-lg-10 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>whenever you are asked to fill in a form on the website, look
                            for the box that you can click to indicate that you do not
                            want the information to be used by anybody for direct marketing purposes</li>
                        <li><i class="fas fa-check"></i>if you have previously agreed to us using your personal
                            information for direct marketing purposes, you may change your
                            mind at any time by writing to or emailing us at care@workermandi.com</li>
                    </ul>
                </div>
            </div>

            <p>We will not sell, distribute or lease your personal information to third parties unless we have your
                permission or are
                required by law to do so. We may use your personal information to send you promotional information about
                third parties
                which we think you may find interesting if you tell us that you wish this to happen.</p>

            <p>If you believe that any information we are holding on you is incorrect or incomplete, please write to or
                email us as
                soon as possible, at the above address. We will promptly correct any information found to be incorrect.
            </p>

<h3>5.Information collected by Mobile Application</h3>
<p>Our Services are primarily provided through the Mobile Application. We may collect and use technical data and related
information, including but not limited to, technical information about your device, system and application software, and
peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other
services to you (if any) related to such Mobile Applications.</p>
<p>
    When you use any of our Mobile Applications, the Mobile Application may automatically collect and store some or all of
    the following information from your mobile device (“Mobile Device Information”), in addition to the Device Information,
    including without limitation:
</p>
<div class="row">
    <div class="col-lg-10 col-md-6">
        <ul class="features-list">
            <li><i class="fas fa-check"></i>Your preferred language</li>
            <li><i class="fas fa-check"></i>Your geolocation</li>
            <li><i class="fas fa-check"></i>Camera and Storage access to upload profile picture. We do not share and files or storage related data to any third party</li>
            <li><i class="fas fa-check"></i>Data from SMS/text messages upon receiving Device permissions for the purposes of (i) issuing and receiving one time
            passwords and other device verification, and (ii) automatically filling verification details. We do not share or
            transfer SMS/ text message data to any third party other than as provided under this Privacy Policy.</li>

        </ul>
    </div>
</div>
<h3>6.Use of Information collected</h3>
<p>Our primary goal in collecting your information is to provide you with an enhanced experience when using the Services.
We use your information to closely monitor which features of the Services are used most, to allow you to view your
search history, rating, and to determine which features we need to focus on improving, including usage patterns and
geographic locations to determine where we should offer or focus services, features and/or resources.</p>
<p>
    Based upon the Protected Information you provide us when registering for an account, we will send you a welcoming
    email to verify your username and password.
</p>


<div class="row">
    <div class="col-lg-10 col-md-6">
        <ul class="features-list">
            <li><i class="fas fa-check"></i>We use the information collected from our Mobile Application so that we are able to serve you the correct app version
            depending on your device type, for troubleshooting and in some cases, marketing purposes.</li>
            <li><i class="fas fa-check"></i>We use your Internet Protocol (IP) address to help diagnose problems with our computer server, and to administer our
            web site(s). Your IP address is used to help identify you, but contains no personal information about you.</li>
            <li><i class="fas fa-check"></i> We will send you strictly service-related announcements on rare occasions when it is necessary to do so. For
            instance, if our Services are temporarily suspended for maintenance, we might send you an email. If you do not wish to
            receive them, you have the option to deactivate your account.</li>
            <li><i class="fas fa-check"></i>We may use the information obtained from you to prevent, discover and investigate violations of this Privacy Policy
            or any applicable terms of service or terms of use for the Mobile Application, and to investigate fraud, chargeback or
            other matters.
            </li>
            <li><i class="fas fa-check"></i>We provide some of your Protected Information (such as your name, contact number, and location) to the Employer or
            Employee who is searching for them respectively, so that the person may contact and find you.</li>
            <li><i class="fas fa-check"></i>We use that geo-location information for various purposes, including for you to be able to view the available person
            in your area that are close to your location.</li>
            <li><i class="fas fa-check"></i>In addition, we may use your Protected Information or Usage Information that we collect about you: (i) to provide you
            with information or services or process transactions that you have requested or agreed to receive including to send you
            electronic newsletters, or to provide you with special offers or promotional materials on behalf of us or third parties;
            (ii) to enable you to participate in a variety of the Services’ features such as online or mobile entry sweepstakes,
            contests or other promotions; (iii) to contact you with regard to your use of the Services and, in our discretion,
            changes to the Services and/or the Services’ policies; (iv) for internal business purposes; (v) for inclusion in our
            data analytics; and (vi) for purposes disclosed at the time you provide your information or as otherwise set forth in
            this Privacy Policy.</li>

        </ul>
    </div>
</div>
</div>
</div>
</section>