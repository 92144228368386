import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./common.service";

@Injectable({
    providedIn: "root",
})
export class GenericCallService {
    _host = "";
    _url = "";
    prm: any = [];

    constructor(
        private _Http: HttpClient,
        private _commonService: CommonService
    ) {
        this._host = _commonService.GetHost();
    }

    postMethodWithoutToken(sp: any, param: any): Observable<any> {
        this._url = this._host + sp;
        try {
            const headers = { "Content-Type": "application/json" };
            return this._Http
                .post<any>(this._url, param, {
                    headers: headers,
                    observe: "response",
                })
                .pipe(
                    map((resp) => {
                        return resp;
                    })
                );
        } catch (error) {
            console.log(error);
        }
    }
}
