import { Component, DoCheck, OnInit } from "@angular/core";
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonService } from "src/app/core/common.service";
import { GenericCallService } from "src/app/core/generic-call.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { AuthenticationService } from "src/app/core/_service/authentication.service";
@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
    title = "my new app";
    loginForm: FormGroup = new FormGroup({});
    sub = new Subscription();
    cityList: any = [];
    phoneNo: any = "";
    userType: any = 4;
    public stateList: Array<any> = [];
    private destroy$ = new Subject<void>();
    skillDropDownSettings: IDropdownSettings = {};
    industryDropDownSettings: IDropdownSettings = {};
    skillDropDownList = [];
    industryDropDownList = [];
    AttributeType: any = [];

    constructor(
        public fomBuilder: FormBuilder,
        private _httpService: GenericCallService,
        private _commonService: CommonService,
        private toastr: ToastrService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}
    ngOnInit() {
        this._commonService.userMobileNo.subscribe((res) => {
            if (res.PhoneNo.length > 0) {
                (this.userType = res.UserType == "Job Seeker" ? 4 : 3),
                    (this.phoneNo = res.PhoneNo);
            } else {
                this.userType = 4;
            }
        });
        this.skillDropDownSettings = {
            singleSelection: false,
            idField: "skillAttributeId",
            textField: "skillAttribute",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableCheckAll: false,
            itemsShowLimit: 4,
            allowSearchFilter: true,
        };
        this.industryDropDownSettings = {
            singleSelection: false,
            idField: "indAttributeId",
            textField: "industryAttribute",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableCheckAll: false,
            itemsShowLimit: 4,
            allowSearchFilter: true,
        };
        this.getSkillTypeList(2);

        this.getIndustryTypeList(4);
        this.initForm();
        this.getStateList();
        this.loginForm
            .get("state")
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val) => {
                this.getCityList();
            });
    }
    initForm = () => {
        this.loginForm = this.fomBuilder.group({
            firstName: new FormControl("", [Validators.required]),
            lastName: new FormControl("", [Validators.required]),
            userName: new FormControl(this.phoneNo, [Validators.required]),
            password: new FormControl("", [Validators.required]),
            address: new FormControl("", [Validators.required]),
            city: new FormControl("", [Validators.required]),
            state: new FormControl("", [Validators.required]),
            country: new FormControl("India", [Validators.required]),
            pinCode: new FormControl("", [Validators.required]),
            dob: new FormControl("", [Validators.required]),
            number: new FormControl(this.phoneNo, [Validators.required]),
            userType: new FormControl(this.userType, [Validators.required]),
            Skills: new FormControl(""),
            Industry: new FormControl(""),
            CompanyName: new FormControl(""),
            gstNo: new FormControl(""),
            referralUserId: new FormControl(""),
        });
    };
    getSkillTypeList = (Id: number) => {
        this._httpService
            .postMethodWithoutToken(
                "AttributeMst/getAttributeListById/" + Id,
                {}
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data && data.body.data) {
                    data.body.data.forEach((element) => {
                        this.AttributeType.push({
                            skillAid: 0,
                            skillAttributeId: Number.parseInt(
                                element.attributeId
                            ),
                            skillAttribute: element.attribute,
                            isActive: true,
                        });
                    });
                    this.skillDropDownList = this.AttributeType;
                }
            });
    };
    getIndustryTypeList = (Id: Number) => {
        this._httpService
            .postMethodWithoutToken(
                "AttributeMst/getAttributeListById/" + Id,
                {}
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data && data.body.data) {
                    var list = [];
                    data.body.data.forEach((element) => {
                        list.push({
                            indAttributeId: Number.parseInt(
                                element.attributeId
                            ),
                            industryAttribute: element.attribute,
                            isActive: true,
                        });
                    });
                    this.industryDropDownList = list;
                }
            });
    };
    signUp = (form: any) => {
        if (form.valid) {
            if (this.userType == 4) {
                if (
                    form.value.Skills.length > 3 ||
                    form.value.Skills.length == 0
                ) {
                    this.toastr.error(
                        "Please select atleast one skill and maximum three."
                    );
                    return;
                }
            }
            const payload = {
                PhoneNo: this.loginForm.value.number
                    ? this.loginForm.value.number
                    : "",
                UserName: this.loginForm.value.userName
                    ? this.loginForm.value.userName
                    : "",
                FirstName: this.loginForm.value.firstName
                    ? this.loginForm.value.firstName
                    : "",
                LastName: this.loginForm.value.lastName
                    ? this.loginForm.value.lastName
                    : "",
                Password: this.loginForm.value.password
                    ? this.loginForm.value.password
                    : "",
                EmailId: "",
                CityCode: this.loginForm.value.city
                    ? this.loginForm.value.city
                    : 0,
                StateCode: this.loginForm.value.state
                    ? this.loginForm.value.state
                    : 0,
                Country: this.loginForm.value.country
                    ? this.loginForm.value.country
                    : "",
                ZipCode: this.loginForm.value.pinCode
                    ? this.loginForm.value.pinCode
                    : "",
                Address: this.loginForm.value.address
                    ? this.loginForm.value.address
                    : "",
                DateOfBirth: this.loginForm.value.dob
                    ? this.loginForm.value.dob
                    : "",
                UserId: 0,
                MobileVerified: 1,
                EmailVerified: 0,
                UserType: this.loginForm.value.userType
                    ? this.loginForm.value.userType
                    : 0,
                CompanyName: this.loginForm.value.CompanyName
                    ? this.loginForm.value.CompanyName
                    : "",
                Skills: this.loginForm.value.Skills
                    ? this.loginForm.value.Skills
                    : [],
                industryType: this.loginForm.value.Industry
                    ? this.loginForm.value.Industry
                    : [],
                LoginUserId: 0,
                gstNo: this.loginForm.value.gstNo
                    ? this.loginForm.value.gstNo
                    : "",
                referralUserId: this.loginForm.value.referralUserId
                    ? this.loginForm.value.referralUserId
                    : "",
            };
            this._httpService
                .postMethodWithoutToken("User/register", payload)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    if (data.status == 200 && data.body.data.isSuccess == 1) {
                        this.toastr.success(data.body.statusMessage);
                        this.login(payload);
                    } else {
                        this.toastr.error(data.body.statusMessage);
                    }
                });
        }
    };
    get firstName() {
        return this.loginForm.get("firstName");
    }
    get lastName() {
        return this.loginForm.get("lastName");
    }
    get dob() {
        return this.loginForm.get("dob");
    }
    get userName() {
        return this.loginForm.get("userName");
    }
    get password() {
        return this.loginForm.get("password");
    }
    get number() {
        return this.loginForm.get("number");
    }
    get address() {
        return this.loginForm.get("address");
    }
    get pinCode() {
        return this.loginForm.get("pinCode");
    }
    getCityList = () => {
        var stateId = Number.parseInt(this.loginForm.controls["state"].value);
        if (stateId && stateId > 0) {
            this._httpService
                .postMethodWithoutToken("Country/getCity", {})
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    if (data.status == 200 && data.body.data) {
                        this.cityList = data.body.data.filter(
                            (o) => Number.parseInt(o.stateId) == stateId
                        );
                    }
                });
        }
    };
    getStateList = () => {
        var countryId = 1;
        this._httpService
            .postMethodWithoutToken("Country/getState", {})
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data.status == 200 && data.body.data) {
                    this.stateList = data.body.data.filter(
                        (o) => o.cntryId == countryId
                    );
                }
            });
    };
    login = (form: any) => {
        this.authenticationService.login(form).subscribe((data) => {
            if (data.status == 200 && data.body.result > 0) {
                localStorage.setItem(
                    "userData",
                    JSON.stringify(data.body.data)
                );
                this.toastr.success("Login Successfully");
                this.router.navigateByUrl("/user-list");
            } else {
                this.toastr.error("Login Failed");
            }
        });
    };
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    // signUp1=(form:any)=> {
    //    console.log(this.loginForm.value)
    //  }
}
