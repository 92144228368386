<app-header-four></app-header-four>
<div class="home-area home-slides owl-carousel owl-theme ">
    <div class="main-banner-section  customImageView  ">
        <div class="w-100 h-100  pt-5 embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" width="100%" height="100%"
                src="https://www.youtube.com/embed/x4VopcSMjJo?si=vEs4Qqb3R3ZLpTx5" allow='autoplay'
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
        </div>
    </div>

</div>
<section class="services-area  pb-20 bg-fafafa">
    <div class="container">
        <div class="section-title">
            <span class="sub-title"></span>
            <h2>Our Services</h2>
            <p>
                Welcome to Worker Mandi, the platform that seamlessly connects job providers with job seekers,
                simplifying the process of finding and offering services like never before.
            </p>
        </div>

        <section>
            <div class="container">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-2 " *ngFor="let item of ourServiceData">

                        <div class="single-services-box max_height">
                            <!-- <div class="icon"> -->
                            <!-- <i class="fa fa-umbrella fa-3x fontsize"></i> -->

                            <div class="icon">
                                <img src={{item.iconPath}} alt="">
                            </div>
                            <!-- </div> -->

                            <h6>{{item.title}}</h6>

                            <!-- <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div> -->
                        </div>

                    </div>
                </div>
            </div>
        </section>