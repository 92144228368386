import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/core/common.service";
import { GenericCallService } from "src/app/core/generic-call.service";

@Component({
    selector: "app-view-invoice",
    templateUrl: "./view-invoice.component.html",
    styleUrls: ["./view-invoice.component.scss"],
})
export class ViewInvoiceComponent implements OnInit {
    sub = new Subscription();
    pdfBase64: string = "";
    constructor(
        private httpService: GenericCallService,
        private commonService: CommonService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        let id = this.route.snapshot.paramMap.get("id");
        if (id.length > 0) {
            this.getInvoice(id);
        }
    }
    getInvoice(docId: any) {
        this.sub = this.httpService
            .postMethodWithoutToken("Document/Showdocument?id=" + docId, {})
            .subscribe((data) => {
                this.pdfBase64 =
                    "data:application/pdf;base64," +
                    data.body.data.responseMessage;
                this.openFile(this.pdfBase64);
                console.log(this.pdfBase64);
            });
    }

    openFile(item: string, type: string = "application/pdf") {
        //for view file in next tab
        let html = "";
        html += "<html>";
        html += '<body style="margin:0!important">';
        html +=
            '<embed width="100%" height="100%" src="' +
            item +
            '" type="' +
            type +
            '" />';
        html += "</body>";
        html += "</html>";

        setTimeout(() => {
            const win = window.open("", "_self");
            win.document.write(html);
        }, 0);
    }
}
