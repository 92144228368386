import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GenericCallService } from "src/app/core/generic-call.service";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/core/common.service";
@Component({
    selector: "app-signup-step-one",
    templateUrl: "./signup-step-one.component.html",
    styleUrls: ["./signup-step-one.component.scss"],
})
export class SignupStepOneComponent implements OnInit {
    constructor(
        public formBuilder: FormBuilder,
        private router: Router,
        private _httpService: GenericCallService,
        private toastr: ToastrService,
        private _commonService: CommonService
    ) {}
    step: any = 0;
    submitOtp: FormGroup;
    submitMobileNo: FormGroup;
    userType: string = "";
    MobileNo: string = "";
    btnDisabled: boolean = false;
    private destroy$ = new Subject<void>();
    ngOnInit(): void {
        this.initializeMobileNoForm();
        this.initializeOtpForm();
    }
    initializeMobileNoForm() {
        this.submitMobileNo = this.formBuilder.group({
            mobileNo: new FormControl("", [
                Validators.required,
                Validators.maxLength(10),
                Validators.minLength(10),
                Validators.pattern(new RegExp("[0-9]")),
            ]),
        });
    }
    initializeOtpForm() {
        this.submitOtp = this.formBuilder.group({
            otp: new FormControl("", [
                Validators.required,
                Validators.maxLength(6),
                Validators.minLength(6),
                Validators.pattern(new RegExp("[0-9]")),
            ]),
        });
    }

    getJobSeeker(value: any) {
        this.step = 1;
        this.userType = value;
    }
    enterMobileNumber(formValue: any) {
        try {
            if (formValue.valid) {
                this.btnDisabled = true;
                this.MobileNo = formValue.value.mobileNo;
                this._httpService
                    .postMethodWithoutToken(
                        "AuthenticationWebApi/sendOtp/" +
                            "new" +
                            "/" +
                            this.MobileNo,
                        {}
                    )
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                        if (data.body.statusCode == 200) {
                            this.step = 2;
                            this.toastr.success(data.body.statusMessage);
                        } else {
                            this.btnDisabled = false;
                            this.toastr.error(data.body.statusMessage);
                        }
                    });
            }
        } catch (error) {
            this.toastr.error(error);
        }
    }
    enterOtp(formValue: any) {
        try {
            if (formValue.valid) {
                this._httpService
                    .postMethodWithoutToken(
                        "AuthenticationWebApi/verifyMobileOTP/" +
                            this.MobileNo +
                            "&" +
                            formValue.value.otp,
                        {}
                    )
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                        if (data.body.statusCode == 200) {
                            const payload = {
                                PhoneNo: this.MobileNo,
                                UserType: this.userType,
                            };
                            this._commonService.userMobileNo.next(payload);
                            this.router.navigateByUrl("/signup");
                        } else {
                            this.toastr.error(data.body.statusMessage);
                        }
                    });
            }
        } catch (error) {
            this.toastr.error(error);
        }
    }
}
