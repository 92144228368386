import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject } from "rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    switchMap,
    takeUntil,
} from "rxjs/operators";
import { CommonService } from "src/app/core/common.service";
import { GenericCallService } from "src/app/core/generic-call.service";

@Component({
    selector: "app-user-list",
    templateUrl: "./user-list.component.html",
    styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit {
    private destroy$ = new Subject<void>();
    userList: any = [];
    searchText = new FormControl("");
    defaultProfileImg: any = "assets/img/profileImg.png";
    displayStyle: boolean = false;
    userData: any = {};
    constructor(
        private _httpService: GenericCallService,
        private _commonService: CommonService,
        private toastr: ToastrService,
        private router: Router
    ) {
        this.searchText.valueChanges
            .pipe(
                debounceTime(1000),
                switchMap((results) => {
                    return new Observable<any>((observer) => {
                        observer.next(results);
                        observer.complete();
                    });
                })
            )
            .subscribe((text) => {
                this.getSearchData(text);
            });
    }

    ngOnInit(): void {
        this.getSearchData("");
    }

    closePopup() {
        this.displayStyle = false;
    }
    getProfile(item: any) {
        this.displayStyle = true;
        this.userData = item;
    }
    getSearchData(text: string) {
        const payload = {
            UserTypeId: 4, // employer = 3 and employee = 4
            SearchText: text,
            SortColumnName: "",
            SortDirection: "",
            StartIndex: 0,
            PageSize: 20,
        };
        this._httpService
            .postMethodWithoutToken("Customer/getcustomers", payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data.status == 200 && data.body.result > 0) {
                    this.userList = data.body.data;
                } else {
                    this.toastr.error(data.body.statusMessage);
                }
            });
    }
    onScrollingFinished() {
        debugger;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
