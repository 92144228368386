<app-header-four></app-header-four>

<div class="page-title-area page-title-contact">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content"></div>
            </div>
        </div>
    </div>
</div>
<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Sign-in Form</h2>
        </div>
        <div class="row">
            <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)">
                <div class="col-md-12">
                    <div class="contact-form">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label for="user">Username</label>
                                    <input
                                        id="user"
                                        type="text"
                                        class="form-control"
                                        name="username"
                                        placeholder="Username"
                                        formControlName="username"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label for="pass">Password</label>
                                    <input
                                        id="pass"
                                        type="password"
                                        name="password"
                                        class="form-control"
                                        placeholder="Password"
                                        formControlName="password"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 text-center">
                                <button
                                    type="submit"
                                    class="default-btn"
                                    [disabled]="!loginForm.valid"
                                >
                                    Login</button
                                ><br />
                                <span
                                    >If you are not register please click
                                    <a href="/signup">Sign-up</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
