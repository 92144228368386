import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "src/app/core/_service/authentication.service";

@Component({
    selector: "app-wm-home",
    templateUrl: "./wm-home.component.html",
    styleUrls: ["./wm-home.component.scss"],
})
export class WmHomeComponent implements OnInit {
   
    @ViewChild("widgetsContent", { read: ElementRef })
    public widgetsContent: ElementRef<any>;
    constructor(private service:AuthenticationService) {
        
    }
     ourServiceData = undefined

    ngOnInit(): void {
      this.ourService()
    }
    ourService(){
let data = this.service.ourServices().subscribe(data => {
   this.ourServiceData=data.data
    console.log("ourServiceData",this.ourServiceData);
  });

    }

    scrollRight() {
        debugger;
        this.widgetsContent.nativeElement.scrollTo({
            left: this.widgetsContent.nativeElement.scrollLeft + 150,
            behavior: "smooth",
        });
    }

    scrollLeft() {
        debugger;
        this.widgetsContent.nativeElement.scrollTo({
            left: this.widgetsContent.nativeElement.scrollLeft - 150,
            behavior: "smooth",
        });
    }
}
