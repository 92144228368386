<app-header-four></app-header-four>

<div class="container" style="margin-top: 210px;">
    <div class="main-body" style="margin-bottom: 30px">
        <div class="row">
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex flex-column align-items-center text-center">
                            <img src="{{
                                    userProfileData.photoNameWithPath
                                        ? userProfileData.photoNameWithPath
                                        : defaultProfileImg
                                }}" alt="Admin" width="110" height="110" class="rounded-circle p-1 bg-primary" />
                            <div class="mt-3">
                                <h4>
                                    {{ userProfileData.fullName | titlecase }}
                                </h4>
                                <h5>{{userProfileData.userName}}(User Name)</h5>
                                <p class="text-secondary mb-1">
                                    <strong>My Skills:</strong> {{ userProfileData.skills }}
                                </p>
                                <p class="text-muted font-size-sm">
                                    <strong>Address:</strong> {{ userProfileData.address }},
                                    {{ userProfileData.city }},
                                    {{ userProfileData.state }}
                                    {{ userProfileData.pinCode }}
                                </p>
                                <!-- <button class="btn btn-primary">Follow</button>
                                <button class="btn btn-outline-primary">
                                    Message
                                </button> -->
                            </div>
                        </div>
                        <!-- <hr class="my-4" />
                        <ul class="list-group list-group-flush">
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                            >
                                <h6 class="mb-0">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-globe me-2 icon-inline"
                                    >
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <line
                                            x1="2"
                                            y1="12"
                                            x2="22"
                                            y2="12"
                                        ></line>
                                        <path
                                            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                                        ></path>
                                    </svg>
                                    Website
                                </h6>
                                <span class="text-secondary"
                                    >https://codervent.com</span
                                >
                            </li>
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                            >
                                <h6 class="mb-0">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-github me-2 icon-inline"
                                    >
                                        <path
                                            d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"
                                        ></path>
                                    </svg>
                                    Github
                                </h6>
                                <span class="text-secondary">codervent</span>
                            </li>
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                            >
                                <h6 class="mb-0">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-twitter me-2 icon-inline text-info"
                                    >
                                        <path
                                            d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"
                                        ></path>
                                    </svg>
                                    Twitter
                                </h6>
                                <span class="text-secondary">@codervent</span>
                            </li>
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                            >
                                <h6 class="mb-0">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-instagram me-2 icon-inline text-danger"
                                    >
                                        <rect
                                            x="2"
                                            y="2"
                                            width="20"
                                            height="20"
                                            rx="5"
                                            ry="5"
                                        ></rect>
                                        <path
                                            d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"
                                        ></path>
                                        <line
                                            x1="17.5"
                                            y1="6.5"
                                            x2="17.51"
                                            y2="6.5"
                                        ></line>
                                    </svg>
                                    Instagram
                                </h6>
                                <span class="text-secondary">codervent</span>
                            </li>
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                            >
                                <h6 class="mb-0">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-facebook me-2 icon-inline text-primary"
                                    >
                                        <path
                                            d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"
                                        ></path>
                                    </svg>
                                    Facebook
                                </h6>
                                <span class="text-secondary">codervent</span>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <form [formGroup]="form" (ngSubmit)="submit(form)">
                                <div class="col-md-12">
                                    <div class="contact-form">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12"
                                                *ngIf="this.userProfileData.custTypeId==3">
                                                <div class="form-group">
                                                    <label for="companyName">Company Name</label>
                                                    <input type="text" class="form-control" id="companyName"
                                                        placeholder="Company Name" formControlName="companyName" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12"
                                                *ngIf="this.userProfileData.custTypeId==3">
                                                <div class="form-group">
                                                    <label for="gstNo">GST No</label>
                                                    <input type="text" class="form-control" id="gstNo"
                                                        placeholder="GST No" formControlName="gstNo" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label for="firstName">First Name</label>
                                                    <input type="text" class="form-control" id="firstName"
                                                        placeholder="First Name" formControlName="firstName" />
                                                    <span *ngIf="
                                                            firstName &&
                                                            firstName.touched &&
                                                            firstName.invalid
                                                        " style="color: red">Enter Your First
                                                        Name</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group mb-3">
                                                    <label for="lastName">Last Name</label>
                                                    <input type="text" id="lastName" class="form-control"
                                                        placeholder="Last Name" formControlName="lastName" />
                                                    <span *ngIf="
                                                            lastName &&
                                                            lastName.touched &&
                                                            lastName.invalid
                                                        " style="color: red">Enter Your Last
                                                        Name</span>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group mb-3">
                                                    <label for="userName">UserName</label>
                                                    <input type="text" id="userName" class="form-control"
                                                        placeholder="Enter User Name" formControlName="userName"
                                                        disabled />
                                                    <span *ngIf="
                                                            userName &&
                                                            userName.touched &&
                                                            userName.invalid
                                                        " style="color: red">
                                                        Enter your
                                                        username</span>
                                                </div>
                                            </div> -->
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group mb-3">
                                                    <label for="dateOfBirth">D.O.B</label>
                                                    <input type="date" id="dateOfBirth"
                                                        placeholder="Enter Date of Birth" class="form-control"
                                                        formControlName="dateOfBirth" />
                                                    <span *ngIf="
                                                            dateOfBirth &&
                                                            dateOfBirth.touched &&
                                                            dateOfBirth.invalid
                                                        " style="color: red">Enter Your D.O.B</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group mb-3">
                                                    <label for="mobileNo">Mobile No</label>
                                                    <input type="phoneNo" id="mobileNo" class="form-control" disabled
                                                        placeholder="Mobile No" formControlName="phoneNo"
                                                        maxlength="10" />
                                                    <span *ngIf="
                                                            phoneNo &&
                                                            phoneNo.touched &&
                                                            phoneNo.invalid
                                                        " style="color: red">Enter Your Mobile
                                                        no</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12"
                                                *ngIf="this.userProfileData.custTypeId==4">
                                                <div class="form-group mb-3">
                                                    <label for="mobileNo">Select your Skills</label>
                                                    <ng-multiselect-dropdown [placeholder]="
                                                            'Select your Skills'
                                                        " [settings]="
                                                            skillDropDownSettings
                                                        " [data]="
                                                            skillDropDownList
                                                        " [formControl]="form.controls['Skills']">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label for="mobileNo">Select your Industry</label>
                                                <div class="form-group mb-3">
                                                    <ng-multiselect-dropdown [placeholder]="
                                                            'Select your Industry'
                                                        " [settings]="
                                                            industryDropDownSettings
                                                        " [data]="
                                                            industryDropDownList
                                                        " [formControl]="form.controls['Industry']">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </div>

                                            <!-- <div class="col-lg-12 col-md-12">
                                                <div class="form-group mb-3">
                                                    <label for="password">Password</label>
                                                    <input type="password" id="password" class="form-control"
                                                        placeholder="Enter Password" formControlName="password" />
                                                    <span *ngIf="
                                                            password &&
                                                            password.touched &&
                                                            password.invalid
                                                        " style="color: red">Enter Your
                                                        Password</span>
                                                </div>
                                            </div> -->



                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group mb-3">
                                                    <label for="address">Address</label>
                                                    <input id="address" class="form-control" cols="30" rows="6"
                                                        placeholder="Address" formControlName="address" />
                                                    <span *ngIf="
                                                            address &&
                                                            address.touched &&
                                                            address.invalid
                                                        " style="color: red">Enter Your
                                                        Address</span>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group mb-3">
                                                    <label for="country">Country</label>
                                                    <input type="text" id="Country" class="form-control"
                                                        placeholder="India" formControlName="country" disabled />
                                                </div>
                                            </div> -->
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group mb-3">
                                                    <label for="state">State</label>
                                                    <select class="form-select"
                                                        aria-label="Example select with button addon"
                                                        formControlName="state" (change)="onItemSelect($event)">
                                                        <option value="" disabled>
                                                            Select State
                                                        </option>
                                                        <option *ngFor="
                                                                let item of stateList ;trackBy:identifyState
                                                            " value="{{
                                                                item.stateId
                                                            }}">
                                                            {{ item.stateName }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group mb-3">
                                                    <label for="inputGroupSelect04">City</label>
                                                    <select class="form-select" id="inputGroupSelect04"
                                                        aria-label="Example select with button addon"
                                                        formControlName="city">
                                                        <option value="">
                                                            Select City
                                                        </option>
                                                        <option class="form-control" *ngFor="
                                                                let item of cityList;trackBy:identifyCity
                                                            " value="{{
                                                                item.cityId
                                                            }}">
                                                            {{ item.cityName }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group mb-3">
                                                    <label for="Pin Code">Pincode</label>
                                                    <input type="number" id="Pin Code" class="form-control"
                                                        placeholder="Enter Pincode" formControlName="pinCode" />
                                                    <span *ngIf="
                                                            pinCode &&
                                                            pinCode.touched &&
                                                            pinCode.invalid
                                                        " style="color: red">Enter Your
                                                        Pincode</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group mb-3">
                                                    <label for="Pin Code">Profile Status </label><span
                                                        style="    font-size: 10px;"> (Click Active to get call from
                                                        job Provider)</span>
                                                    <div class="col-sm-12">Deactive
                                                        <label class="switch">
                                                            <input type="checkbox" formControlName="Status">
                                                            <span class="slider"></span>
                                                        </label>Active
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-lg-6 col-md-6"
                                                *ngIf="this.userProfileData.custTypeId==4 && Status.value==true">
                                                <div class="form-check form-check-inline"
                                                    *ngFor="let item of getJobTypeData.controls; let i=index">
                                                    <input class="form-check-input" type="checkbox" [formControl]="item"
                                                        id="jobTypeCheckBox{{i}}" (change)="getSelectJobType()">
                                                    <label class="form-check-label" for="jobTypeCheckBox{{i}}">
                                                        {{JobTypeArray[i].name}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6"
                                                *ngIf="this.userProfileData.custTypeId==4 && Status.value==true">
                                                <div class="form-group mb-3">
                                                    <label for="Pin Code">Calling Hours </label><span
                                                        style="    font-size: 10px;"> (When job provider call
                                                        you?)</span>
                                                    <div class="row mb-3">
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="col-md-12 col-sm-12">
                                                                <span class="mb-0">From Time</span>
                                                            </div>
                                                            <div class="col-md-12 col-sm-12 text-secondary">
                                                                <input type="time" formControlName="FromTime"
                                                                    class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="col-md-12 col-sm-12">
                                                                <span class="mb-0">To Time</span>
                                                            </div>
                                                            <div class="col-md-12 col-sm-12 text-secondary">
                                                                <input type="time" formControlName="ToTime"
                                                                    class="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6"
                                                *ngIf="this.userProfileData.custTypeId==4 && Status.value==true">
                                                <div class="form-group">
                                                    <label for="Pin Code">Job Priority</label><br />
                                                    <input type="radio" value="High" formControlName="JobPriority"> High
                                                    <input type="radio" value="Low" formControlName="JobPriority"> Low

                                                </div>
                                                <span style="font-size: 10px;" *ngIf="JobPriority.value=='High'">मैं
                                                    बेरोजगार हूँ , मुझे तुरंत नौकरी
                                                    चाहिये । </span>
                                                <span style="font-size: 10px;" *ngIf="JobPriority.value=='Low'">मैं
                                                    नौकरी कर रहा हूँ लेकिन बदलना
                                                    चाहता हूँ ।
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6"
                                                *ngIf="this.userProfileData.custTypeId==4 && !this.userProfileData.FreeBank">
                                                <div class="form-group">
                                                    <label for="Pin Code">Apply for free saving bank
                                                        account</label><br />
                                                    <input type="radio" value="Yes" formControlName="FreeBank"> Yes
                                                    <input type="radio" value="No" formControlName="FreeBank"> No

                                                </div>
                                                <span style="font-size: 10px;" *ngIf="FreeBank.value=='Yes'">
                                                    फ्री बैंक खाता के लिए आवेदन करें। </span>
                                                <span style="font-size: 10px;" *ngIf="FreeBank.value=='No'">
                                                    फ्री बैंक खाता नहीं चाहिये।
                                                </span>
                                            </div>
                                            <div class="col-lg-3 col-md-3" *ngIf="this.userProfileData.custTypeId==3">
                                                <div class="form-group mb-3">
                                                    <label for="Pin Code">Office Hours</label>
                                                    <input type="text" placeholder="From to To" class="form-control"
                                                        formControlName="WorkingHours">
                                                </div>
                                            </div>
                                            <p><strong style="color: red;">*</strong>You can not modify any thing right
                                                now.</p>
                                            <div class="col-lg-12 col-md-12 text-center" *ngIf="false">
                                                <button type="submit" class="default-btn">
                                                    Submit <span></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- <form [formGroup]="form" (ngSubmit)="submit(form)">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">First Name</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input
                                        type="text"
                                        formControlName="firstName"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Last Name</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="lastName"
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Email</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input
                                        type="text"
                                        formControlName="email"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Phone</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input
                                        type="text"
                                        formControlName="phone"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Address</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input
                                        type="text"
                                        formControlName="address"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col-sm-9 text-secondary">
                                    <button
                                        type="submit"
                                        class="btn btn-primary px-4"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form> -->
                </div>
                <!-- <div class="row" style="margin-top: 20px">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="d-flex align-items-center mb-3">
                                    Project Status
                                </h5>
                                <p>Web Design</p>
                                <div class="progress mb-3 h-5">
                                    <div
                                        role="progressbar"
                                        aria-valuenow="80"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        class="progress-bar bg-primary w-75"
                                    ></div>
                                </div>
                                <p>Website Markup</p>
                                <div class="progress mb-3 h-5">
                                    <div
                                        role="progressbar"
                                        aria-valuenow="72"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        class="progress-bar bg-danger w-25"
                                    ></div>
                                </div>
                                <p>One Page</p>
                                <div class="progress mb-3 h-5">
                                    <div
                                        role="progressbar"
                                        aria-valuenow="89"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        class="progress-bar bg-success w-50"
                                    ></div>
                                </div>
                                <p>Mobile Template</p>
                                <div class="progress mb-3 h-5">
                                    <div
                                        role="progressbar"
                                        aria-valuenow="55"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        class="progress-bar bg-warning w-75"
                                    ></div>
                                </div>
                                <p>Backend API</p>
                                <div class="progress h-5">
                                    <div
                                        role="progressbar"
                                        aria-valuenow="66"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        class="progress-bar bg-info w-25"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>