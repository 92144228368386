import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
// import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { HeaderOneComponent } from "./components/layouts/header-one/header-one.component";
// import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
// import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
// import { HomeFourComponent } from "./components/pages/home-four/home-four.component";
// import { HeaderTwoComponent } from "./components/layouts/header-two/header-two.component";
// import { HeaderThreeComponent } from "./components/layouts/header-three/header-three.component";
import { HeaderFourComponent } from "./components/layouts/header-four/header-four.component";
import { AboutComponent } from "./components/pages/about/about.component";
// import { TeamComponent } from "./components/pages/team/team.component";
// import { PricingComponent } from "./components/pages/pricing/pricing.component";
// import { TestimonialsComponent } from "./components/pages/testimonials/testimonials.component";
// import { FaqComponent } from "./components/pages/faq/faq.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
// import { HomeInsuranceComponent } from "./components/pages/home-insurance/home-insurance.component";
// import { BusinessInsuranceComponent } from "./components/pages/business-insurance/business-insurance.component";
// import { HealthInsuranceComponent } from "./components/pages/health-insurance/health-insurance.component";
// import { TravelInsuranceComponent } from "./components/pages/travel-insurance/travel-insurance.component";
// import { CarInsuranceComponent } from "./components/pages/car-insurance/car-insurance.component";
// import { LifeInsuranceComponent } from "./components/pages/life-insurance/life-insurance.component";
// import { CaseStudyComponent } from "./components/pages/case-study/case-study.component";
// import { CaseStudyDetailsComponent } from "./components/pages/case-study-details/case-study-details.component";
// import { EventsDetailsComponent } from "./components/pages/events-details/events-details.component";
// import { EventsComponent } from "./components/pages/events/events.component";
// import { ServicesOneComponent } from "./components/pages/services-one/services-one.component";
// import { ServicesTwoComponent } from "./components/pages/services-two/services-two.component";
// import { BlogGridComponent } from "./components/pages/blog-grid/blog-grid.component";
// import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";
// import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { TermConditionsComponent } from "./components/pages/term-conditions/term-conditions.component";
import { WmHomeComponent } from "./components/pages/wm-home/wm-home.component";
import { ViewInvoiceComponent } from "./components/pages/view-invoice/view-invoice.component";
import { HttpClientModule } from "@angular/common/http";
import { SigninComponent } from "./components/pages/auth/signin/signin.component";
import { SignupComponent } from "./components/pages/auth/signup/signup.component";
import { SignupStepOneComponent } from "./components/pages/auth/signup-step-one/signup-step-one.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { UserProfileComponent } from "./components/pages/user-profile/user-profile.component";
import { UserListComponent } from "./components/pages/user-list/user-list.component";
import { ScrollTrackerDirective } from "./core/directives/scroll-tracker.directive";
import { FranchiseformComponent } from "./components/franchiseform/franchiseform.component";

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        //HomeOneComponent,
        FooterComponent,
        HeaderOneComponent,
        // HomeTwoComponent,
        // HomeThreeComponent,
        // HomeFourComponent,
        // HeaderTwoComponent,
        // HeaderThreeComponent,
        HeaderFourComponent,
        AboutComponent,
        // TeamComponent,
        // PricingComponent,
        // TestimonialsComponent,
        // FaqComponent,
        ErrorComponent,
        ContactComponent,
        // HomeInsuranceComponent,
        // BusinessInsuranceComponent,
        // HealthInsuranceComponent,
        // TravelInsuranceComponent,
        // CarInsuranceComponent,
        // LifeInsuranceComponent,
        // CaseStudyComponent,
        // CaseStudyDetailsComponent,
        // EventsDetailsComponent,
        // EventsComponent,
        // ServicesOneComponent,
        // ServicesTwoComponent,
        // BlogGridComponent,
        // BlogRightSidebarComponent,
        // BlogDetailsComponent,
        PrivacyPolicyComponent,
        TermConditionsComponent,
        WmHomeComponent,
        ViewInvoiceComponent,
        SigninComponent,
        SignupComponent,
        SignupStepOneComponent,
        UserProfileComponent,
        UserListComponent,
        ScrollTrackerDirective,
        FranchiseformComponent
        
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
