import { Component, Input, OnInit } from "@angular/core";
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GenericCallService } from "src/app/core/generic-call.service";
import { IUserProfileInterface } from "./user-interface";
import { formatDate } from "@angular/common";

@Component({
    selector: "app-user-profile",
    templateUrl: "./user-profile.component.html",
    styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
    form: FormGroup = new FormGroup({});
    @Input() userProfileData: IUserProfileInterface = {};
    userProfile: Array<any> = [];
    userData: IUserProfileInterface = {};
    defaultProfileImg: string = "assets/img/profileImg.png";
    skillDropDownSettings: IDropdownSettings = {};
    industryDropDownSettings: IDropdownSettings = {};
    skillDropDownList: Array<any> = [];
    industryDropDownList: Array<any> = [];
    AttributeType: Array<any> = [];
    sub = new Subscription();
    cityList: Array<any> = [];
    cityMainList: Array<any> = [];
    public stateList: Array<any> = [];
    private destroy$ = new Subject<void>();
    JobTypeArray: Array<any> = [
        { name: "Full Time", selected: true },
        { name: "Part Time", selected: false },
    ];
    selectedJobType: Array<any> = [];
    constructor(
        public formBuilder: FormBuilder,
        private _httpService: GenericCallService
    ) {}

    ngOnInit() {
        this.initializeForm();
        this.getStateList();
        this.getCityList();
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.getUserProfileData(this.userData.userId);
        this.skillDropDownSettings = {
            singleSelection: false,
            idField: "skillAttributeId",
            textField: "skillAttribute",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableCheckAll: false,
            itemsShowLimit: 4,
            allowSearchFilter: true,
        };
        this.industryDropDownSettings = {
            singleSelection: false,
            idField: "indAttributeId",
            textField: "industryAttribute",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableCheckAll: false,
            itemsShowLimit: 4,
            allowSearchFilter: true,
        };
        this.getSkillTypeList(2);
        this.getIndustryTypeList(4);
    }

    initializeForm = () => {
        this.form = this.formBuilder.group({
            firstName: new FormControl(
                this.userProfileData.firstName
                    ? this.userProfileData.firstName
                    : "",
                [Validators.required]
            ),
            lastName: new FormControl(
                this.userProfileData.lastName
                    ? this.userProfileData.lastName
                    : "",
                [Validators.required]
            ),
            userName: new FormControl(
                this.userProfileData.userName
                    ? this.userProfileData.userName
                    : "",
                [Validators.required]
            ),
            address: new FormControl(
                this.userProfileData.address
                    ? this.userProfileData.address
                    : "",
                [Validators.required]
            ),
            country: new FormControl("India", [Validators.required]),
            state: new FormControl(
                this.userProfileData.stateCode
                    ? this.userProfileData.stateCode
                    : "",
                [Validators.required]
            ),
            city: new FormControl(
                this.userProfileData.cityCode
                    ? this.userProfileData.cityCode
                    : "",
                [Validators.required]
            ),

            pinCode: new FormControl(
                this.userProfileData.pinCode
                    ? this.userProfileData.pinCode
                    : "",
                [Validators.required]
            ),
            dateOfBirth: new FormControl(
                this.userProfileData.dateOfBirth
                    ? formatDate(
                          this.userProfileData.dateOfBirth,
                          "yyyy-MM-dd",
                          "en"
                      )
                    : "",
                [Validators.required]
            ),
            phoneNo: new FormControl(
                this.userProfileData.phoneNo
                    ? this.userProfileData.phoneNo
                    : "",
                [Validators.required]
            ),
            custTypeId: new FormControl(
                this.userProfileData.custTypeId
                    ? this.userProfileData.custTypeId
                    : "",
                [Validators.required]
            ),
            Skills: new FormControl(
                this.userProfileData.jsonSkills
                    ? JSON.parse(this.userProfileData.jsonSkills).skills
                    : []
            ),
            Industry: new FormControl(
                this.userProfileData.industry
                    ? JSON.parse(this.userProfileData.industry).industry
                    : []
            ),
            Status: new FormControl(this.userProfileData.status ? true : true),
            GstNo: new FormControl(
                this.userProfileData.GstNo ? this.userProfileData.GstNo : ""
            ),
            JobPriority: new FormControl(
                this.userProfileData.JobPriority
                    ? this.userProfileData.JobPriority
                    : "Low"
            ),
            companyName: new FormControl(
                this.userProfileData.companyName
                    ? this.userProfileData.companyName
                    : ""
            ),
            FreeBank: new FormControl(true),
            FromTime: new FormControl(
                this.userProfileData.FromTime
                    ? this.userProfileData.FromTime
                    : null
            ),
            ToTime: new FormControl(
                this.userProfileData.ToTime ? this.userProfileData.ToTime : null
            ),
            WorkingHours: new FormControl(
                this.userProfileData.workingHours
                    ? this.userProfileData.workingHours
                    : ""
            ),
            JobType: this.addJobType(),
        });
    };

    addJobType = () => {
        // this.JobTypeArray = [{ name: "Part Time", selected: true }];
        const arr = this.JobTypeArray.map((d) => {
            return this.formBuilder.control(d.selected);
        });
        return this.formBuilder.array(arr);
    };
    getSelectJobType = () => {
        this.selectedJobType = [];
        this.getJobTypeData.controls.forEach((control, i) => {
            if (control.value) {
                this.selectedJobType.push(this.JobTypeArray[i]);
            }
        });
        console.log(this.selectedJobType);
    };
    onItemSelect = (event: any) => {
        this.cityList = this.cityMainList.filter(
            (o) => o.stateId == event.target.value
        );
    };
    identifyCity = (index: number, item: any) => {
        return item.cityName;
    };
    identifyState = (index: number, item: any) => {
        return item.stateName;
    };

    get firstName() {
        return this.form.get("firstName");
    }
    get lastName() {
        return this.form.get("lastName");
    }
    get dateOfBirth() {
        return this.form.get("dateOfBirth");
    }
    get userName() {
        return this.form.get("userName");
    }
    get phoneNo() {
        return this.form.get("phoneNo");
    }
    get address() {
        return this.form.get("address");
    }
    get pinCode() {
        return this.form.get("pinCode");
    }
    get JobPriority() {
        return this.form.get("JobPriority");
    }
    get Status() {
        return this.form.get("Status");
    }
    get FreeBank() {
        return this.form.get("FreeBank");
    }
    get custTypeId() {
        return this.form.get("custTypeId");
    }
    get getJobTypeData() {
        return <FormArray>this.form.get("JobType");
    }
    getUserProfileData = (userId: Number) => {
        this._httpService
            .postMethodWithoutToken("Customer/getcustomerbyid/" + userId, {})
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data.body.result > 0) {
                    this.userProfile = this.userProfileData = data.body.data;
                    this.initializeForm();
                }
            });
    };
    getSkillTypeList = (Id: number) => {
        this._httpService
            .postMethodWithoutToken(
                "AttributeMst/getAttributeListById/" + Id,
                {}
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data && data.body.data) {
                    data.body.data.forEach((element) => {
                        this.AttributeType.push({
                            skillAid: 0,
                            skillAttributeId: Number.parseInt(
                                element.attributeId
                            ),
                            skillAttribute: element.attribute,
                            isActive: true,
                        });
                    });
                    this.skillDropDownList = this.AttributeType;
                }
            });
    };
    getIndustryTypeList = (Id: Number) => {
        this._httpService
            .postMethodWithoutToken(
                "AttributeMst/getAttributeListById/" + Id,
                {}
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data && data.body.data) {
                    var list = [];
                    data.body.data.forEach((element) => {
                        list.push({
                            indAttributeId: Number.parseInt(
                                element.attributeId
                            ),
                            industryAttribute: element.attribute,
                            isActive: true,
                        });
                    });
                    this.industryDropDownList = list;
                }
            });
    };
    getCityList = () => {
        this._httpService
            .postMethodWithoutToken("Country/getCity", {})
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data.status == 200 && data.body.data) {
                    var stateId = Number.parseInt(
                        this.form.controls["state"].value
                    );
                    this.cityMainList = data.body.data;
                    this.cityList = data.body.data.filter(
                        (o) => Number.parseInt(o.stateId) == stateId
                    );
                }
            });
    };
    getStateList = () => {
        var countryId = 1;
        this._httpService
            .postMethodWithoutToken("Country/getState", {})
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data.status == 200 && data.body.data) {
                    this.stateList = data.body.data.filter(
                        (o) => o.cntryId == countryId
                    );
                }
            });
    };
    submit = (form: any) => {
        debugger;
    };
}
