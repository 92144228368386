<footer class="footer-area">
    <div class="container">
        <!-- <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="subscribe-content">
                        <h2>Join Our Newsletter</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="subscribe-form">
                        <form class="newsletter-form">
                            <input type="email" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                            <button type="submit">Subscribe Now <i class="flaticon-right-chevron"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/white-logo.png" width="50%" alt="logo"></a>
                        <p>India's First Mobile App here you will get any Skilled/Unskilled Workers & Jobs at your
                            nearest location.</p>
                    </div>

                    <ul class="social">
                        <li><a href="https://www.facebook.com/Workermandi/" target="_blank"><i
                                    class="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://twitter.com/workermandi" target="_blank"><i class="fab fa-twitter"></i></a>
                        </li>
                        <li><a href="https://www.youtube.com/@workermandi" target="_blank"><i
                                    class="fab fa-youtube"></i></a></li>
                        <li><a href="https://www.instagram.com/workermandi/" target="_blank"><i
                                    class="fab fa-instagram"></i></a></li>
                        <li><a href="https://play.google.com/store/apps/details?id=com.workermandi" target="_blank"><i
                                    class="fab fa-google-play"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Home</a></li>
                        <!-- <li><a routerLink="/">Our Team</a></li> -->
                        <li><a routerLink="/about">About Us</a></li>
                        <!-- <li><a routerLink="/">Latest News</a></li> -->
                        <li><a routerLink="/">Work With Us</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <!-- <li><a routerLink="/">Our Events</a></li> -->
                        <li><a routerLink="/term-condition">Terms & Conditions</a></li>
                        <!-- <li><a routerLink="/">Our Packages</a></li> -->
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li><a routerLink="/franchise-form">Partner With Us</a></li>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.workermandi">
                                <img src="https://w7.pngwing.com/pngs/52/715/png-transparent-google-play-logo-google-play-computer-icons-app-store-google-text-logo-sign-thumbnail.png"
                                    alt=""></a>
                            <a href="https://apps.apple.com/in/app/worker-mandi/id6455685314">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/800px-Download_on_the_App_Store_Badge.svg.png"
                                    alt=""></a>

                        </li>


                    </ul>
                </div>
            </div>


            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li><span>Location:</span>
                            <!-- <a href="https://goo.gl/maps/T5gSLkHN7vw8s6r57"> -->
                            C 63, Ground Floor, Phase 1, Urban Estate,
                            Ludhiana Punjab 141010
                            <!-- </a> -->
                        </li>
                        <li><span>Email:</span> <a href="mailto:care@workermandi.com">care@workermandi.com</a></li>
                        <li><span>Phone:</span> <a href="tel:6262562707">+(91) 6262562707</a></li>
                        <!-- <li><span>Fax:</span> <a href="tel:12129876543">+1-212-9876543</a></li> -->
                        <!-- <li><a href="https://goo.gl/maps/T5gSLkHN7vw8s6r57" target="_blank">View Location on GoogleMap</a></li> -->
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>© WorkerMandi</p>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/term-condition">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="fas fa-chevron-up"></i><i class="fas fa-chevron-up"></i></div>

<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>About Us</h2>
                <p>We believe brand interaction is key in communication. Real innovations and a positive customer
                    experience are the heart of successful communication. No fake products and services. The customer is
                    king, their lives and needs are the inspiration.</p>
            </div>
        </div>

        <div class="sidebar-instagram-feed">
            <h2>Instagram</h2>

            <ul>
                <li><a href="#"><img src="assets/img/blog-image/img1.jpg" alt="image"></a></li>
                <li><a href="#"><img src="assets/img/blog-image/img2.jpg" alt="image"></a></li>
                <li><a href="#"><img src="assets/img/blog-image/img3.jpg" alt="image"></a></li>
                <li><a href="#"><img src="assets/img/blog-image/img4.jpg" alt="image"></a></li>
                <li><a href="#"><img src="assets/img/blog-image/img5.jpg" alt="image"></a></li>
                <li><a href="#"><img src="assets/img/blog-image/img6.jpg" alt="image"></a></li>
                <li><a href="#"><img src="assets/img/blog-image/img7.jpg" alt="image"></a></li>
                <li><a href="#"><img src="assets/img/blog-image/img8.jpg" alt="image"></a></li>
            </ul>
        </div>

        <div class="sidebar-contact-area">
            <div class="sidebar-contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:0881306298615">+088 130 629 8615</a>
                        <span>OR</span>
                        <a href="mailto:care@workermandi.com">care@workermandi.com</a>
                    </h2>

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <span class="close-btn sidebar-modal-close-btn"><i class="flaticon-cross-out"></i></span>
    </div>
</div>