<app-header-four></app-header-four>

<div class="page-title-area page-title-contact">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content"></div>
            </div>
        </div>
    </div>
</div>

<!-- <section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Message Us</span>
            <h2>Drop us Message for any Query</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone-number" class="form-control" placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" cols="30" rows="6"
                                        placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message <span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div id="map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96573.31103687892!2d-73.91097358959159!3d40.85176866820105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c28b553a697cb1%3A0x556e43a78ff15c77!2sThe%20Bronx%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1589312138561!5m2!1sen!2sbd"></iframe>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">

                <h1>Worker Mandi</h1>

                <h5>Office Location</h5>
                <div>
                    <a href="https://goo.gl/maps/T5gSLkHN7vw8s6r57">C 63, Ground Floor, Phase 1, Urban Estate,<br />
                        Ludhiana Punjab 141010</a>
                </div>
                <br>

                <h5>Contact Info</h5>

                <span>Email: </span> <a href="mailto: care@workermandi.com">care@workermandi.com</a><br>
                <span>Contact no.: </span> <a href="tel: 6262562707">+(91) 6262562707</a><br>
                <span><small>WhatsApp available at this number.</small></span>


                <ul class="footer-contact-info">

                    <span>Email:</span> <a href="mailto: care@workermandi.com">care@workermandi.com</a><br>
                    <span>Phone:</span> <a href="tel: 6262562707">+(91) 6262562707</a><br>
                </ul>

            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <div class="contact-info-content">
                        <h3>Contact us by Phone Number or Email Address</h3>
                        <h2>
                            <a href="tel:6262562707">+(91) 6262562707</a>
                            <span>OR</span>
                            <a href="mailto:care@workermandi.com">care@workermandi.com</a>
                        </h2>
                        <ul class="social">
                            <li><a href="https://twitter.com/workermandi" target="_blank"><i
                                        class="fab fa-twitter"></i></a></li>
                            <li><a href="https://www.youtube.com/@workermandi" target="_blank"><i
                                        class="fab fa-youtube"></i></a></li>
                            <li><a href="https://www.facebook.com/Workermandi/" target="_blank"><i
                                        class="fab fa-facebook-f"></i></a>
                            </li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="https://www.instagram.com/workermandi/" target="_blank"><i
                                        class="fab fa-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


    </div>

    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>


 -->

<div class="container" *ngIf="step == 0">
    <!-- <div class="content"> -->
    <div class="main">
        <div>
            <h1>Signup Account</h1>
        </div>
        <div class="lower">
            <div>
                <button class="btn1" (click)="getJobSeeker('Job Seeker')">
                    Job Seeker
                </button>
            </div>
            <div>
                <button class="btn1" (click)="getJobSeeker('Job Provider')">
                    Job Provider
                </button>
            </div>
        </div>
    </div>
    <!-- </div> -->
</div>

<div class="container1" *ngIf="step == 1">
    <form [formGroup]="submitMobileNo" (ngSubmit)="enterMobileNumber(submitMobileNo)">
        <div class="main1">
            <h3>Enter Mobile Number</h3>
            <div>
                <input class="no" type="text" formControlName="mobileNo" />
            </div>
            <button class="btn2" type="submit" [disabled]="btnDisabled">
                <span *ngIf="btnDisabled" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                Submit
            </button>
            <!-- <button class="btn2" type="submit" [disabled]="btnDisabled">
                Submit
            </button> -->
        </div>
    </form>
</div>

<div class="container2" *ngIf="step == 2">
    <form [formGroup]="submitOtp" (ngSubmit)="enterOtp(submitOtp)">
        <div class="main2">
            <h3>Verify OTP</h3>
            <div>
                <input class="txt" type="text" placeholder="Enter OTP" formControlName="otp" />
            </div>

            <button class="btn3" type="submit">Verify</button>
        </div>
    </form>
</div>