import { Component } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "src/app/core/_service/authentication.service";

@Component({
    selector: "app-signin",
    templateUrl: "./signin.component.html",
    styleUrls: ["./signin.component.scss"],
})
export class SigninComponent {
    loginForm: FormGroup = new FormGroup({});
    constructor(
        public formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}
    ngOnInit() {
        this.initializeForm();
    }
    initializeForm = () => {
        this.loginForm = this.formBuilder.group({
            username: new FormControl("", [Validators.required]),
            password: new FormControl("", [Validators.required]),
        });
    };
    login = (form: any) => {
        debugger;
        if (form.valid) {
            this.authenticationService.login(form.value).subscribe((data) => {
                if (data.status == 200 && data.body.result > 0) {
                    localStorage.setItem(
                        "userData",
                        JSON.stringify(data.body.data)
                    );
                    this.toastr.success("Login Successfully");
                    this.router.navigateByUrl("/user-profile");
                } else {
                    this.toastr.error("Login Failed");
                }
            });
        }
    };
    ngOnDestroy(): void {}
}
