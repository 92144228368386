import { Component, OnInit } from "@angular/core";
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonService } from "src/app/core/common.service";
import { GenericCallService } from "src/app/core/generic-call.service";

@Component({
    selector: "app-franchiseForm",
    templateUrl: "./franchiseform.component.html",
    styleUrls: ["./franchiseform.component.scss"],
})
export class FranchiseformComponent implements OnInit {
    title = "my new app";
    franchiseForm: FormGroup = new FormGroup({});
    sub = new Subscription();
    cityList: any = [];
    phoneNo: any = "";
    userType: any = 4;
    public stateList: Array<any> = [];
    private destroy$ = new Subject<void>();
    AttributeType: any = [];

    step: any = 0;
    submitOtpForm: FormGroup;
    submitMobileForm: FormGroup;
    MobileNo: string = "";
    btnDisabled: boolean = false;

    constructor(
        public formBuilder: FormBuilder,
        private _httpService: GenericCallService,
        private _commonService: CommonService,
        private toastr: ToastrService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.initializeMobileNoForm();
        this.initializeOtpForm();
        this.getStateList();

        this.franchiseForm
            .get("state")
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val) => {
                this.getCityList();
            });
    }
    initializeMobileNoForm() {
        this.submitMobileForm = this.formBuilder.group({
            mobileNo: new FormControl("", [
                Validators.required,
                Validators.maxLength(10),
                Validators.minLength(10),
                Validators.pattern(new RegExp("[0-9]")),
            ]),
        });
    }
    initializeOtpForm() {
        this.submitOtpForm = this.formBuilder.group({
            otp: new FormControl("", [
                Validators.required,
                Validators.maxLength(6),
                Validators.minLength(6),
                Validators.pattern(new RegExp("[0-9]")),
            ]),
        });
    }

    initForm = () => {
        this.franchiseForm = this.formBuilder.group({
            firstName: new FormControl("", [Validators.required]),
            lastName: new FormControl("", [Validators.required]),

            email: new FormControl("", [Validators.required]),
            phoneNo: new FormControl(this.MobileNo, [Validators.required]),
            currentProfession: new FormControl("", [Validators.required]),
            city: new FormControl("", [Validators.required]),
            state: new FormControl("", [Validators.required]),
            pinCode: new FormControl("", [Validators.required]),
            country: new FormControl("India", [Validators.required]),
            discription: new FormControl("", [Validators.required]),
            howDidYouKnow: new FormControl("", [Validators.required]),
        });
    };
    submitFranchiseForm = (form: any) => {
        console.log(form.value);
        if (form.valid) {
            const payload = {
                PhoneNo: this.franchiseForm.value.phoneNo
                    ? this.franchiseForm.value.phoneNo
                    : this.MobileNo,
                CurrentProfession: this.franchiseForm.value.currentProfession
                    ? this.franchiseForm.value.currentProfession
                    : "",
                FirstName: this.franchiseForm.value.firstName
                    ? this.franchiseForm.value.firstName
                    : "",
                LastName: this.franchiseForm.value.lastName
                    ? this.franchiseForm.value.lastName
                    : "",
                Discription: this.franchiseForm.value.discription
                    ? this.franchiseForm.value.discription
                    : "",
                Email: this.franchiseForm.value.email
                    ? this.franchiseForm.value.email
                    : "",
                CityId: this.franchiseForm.value.city
                    ? this.franchiseForm.value.city
                    : 0,
                StateId: this.franchiseForm.value.state
                    ? this.franchiseForm.value.state
                    : 0,
                HowDidYouKnow: this.franchiseForm.value.howDidYouKnow
                    ? this.franchiseForm.value.howDidYouKnow
                    : "",
                PinCode: this.franchiseForm.value.pinCode
                    ? this.franchiseForm.value.pinCode
                    : "",
            };
            this._httpService
                .postMethodWithoutToken("Franchise/FranchiseRequest", payload)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    if (data.status == 200 && data.body.statusCode == 200) {
                        this.toastr.success(data.body.statusMessage);
                    } else {
                        this.toastr.error(data.body.statusMessage);
                    }
                });
        }
    };
    get firstName() {
        return this.franchiseForm.get("firstName");
    }
    get lastName() {
        return this.franchiseForm.get("lastName");
    }
    get email() {
        return this.franchiseForm.get("email");
    }
    get currentProfession() {
        return this.franchiseForm.get("CurrentProfession");
    }

    get discription() {
        return this.franchiseForm.get("address");
    }
    get pinCode() {
        return this.franchiseForm.get("pinCode");
    }
    getCityList = () => {
        var stateId = Number.parseInt(
            this.franchiseForm.controls["state"].value
        );
        if (stateId && stateId > 0) {
            this._httpService
                .postMethodWithoutToken("Country/getCity", {})
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    if (data.status == 200 && data.body.data) {
                        this.cityList = data.body.data.filter(
                            (o) => Number.parseInt(o.stateId) == stateId
                        );
                    }
                });
        }
    };
    getStateList = () => {
        var countryId = 1;
        this._httpService
            .postMethodWithoutToken("Country/getState", {})
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data.status == 200 && data.body.data) {
                    this.stateList = data.body.data.filter(
                        (o) => o.cntryId == countryId
                    );
                }
            });
    };

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    enterMobileNumber(formValue: any) {
        try {
            if (formValue.valid) {
                this.btnDisabled = true;
                this.MobileNo = formValue.value.mobileNo;
                this._httpService
                    .postMethodWithoutToken(
                        "AuthenticationWebApi/sendOtp/" +
                            "new" +
                            "/" +
                            this.MobileNo,
                        {}
                    )
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                        if (data.body.statusCode == 200) {
                            this.step = 1;
                            this.toastr.success(data.body.statusMessage);
                        } else {
                            this.btnDisabled = false;
                            this.toastr.error(data.body.statusMessage);
                        }
                    });
            }
        } catch (error) {
            this.toastr.error(error);
        }
    }
    redirectPage() {
        setTimeout(() => {
            // console.log("work");
            this.router.navigateByUrl("");
        }, 3000);
    }
    verifyOTP(formValue: any) {
        try {
            if (formValue.valid) {
                this._httpService
                    .postMethodWithoutToken(
                        "AuthenticationWebApi/verifyMobileOTP/" +
                            this.MobileNo +
                            "&" +
                            formValue.value.otp,
                        {}
                    )
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                        if (data.body.statusCode == 200) {
                            this.franchiseForm.patchValue({
                                phoneNo: this.MobileNo,
                            });
                            this.step = 2;
                        } else {
                            this.toastr.error(data.body.statusMessage);
                        }
                    });
            }
        } catch (error) {
            this.toastr.error(error);
        }
    }
}
