<app-header-four></app-header-four>

<div class="page-title-area page-title-term">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Terms & Conditions</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Terms & Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <div class="content">
                        <h3>Term and Conditions</h3>
                        <h3>Last updated: 15 March, 2023</h3>
                        <p>The Website Owner <strong>Satcap India Technology Pvt. Ltd.</strong>, including subsidiaries and affiliates (“Website” or “Website Owner” or “we” or “us” or “our”) whose
                        registered/operational office is C 63, GROUND FLOOR, PHASE 1, URBAN ESTATE, Ludhiana Ludhiana PUNJAB 141010, provides
                        the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”)
                        (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website
                        terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be
                        applicable to a specific section or module of the website.</p>

                        <p>Welcome to our website. By using these services, you represent and warrant that you have full legal capacity and
                        authority to agree and to bind yourself to these terms. If you represent any other person, you confirm and represent
                        that you have the necessary power and authority to bind such person to these terms. If you continue to browse and use
                        this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together
                        with our privacy policy govern Worker Mandi's relationship with you in relation to this website.</p>


                        <p>By using our services, you agree that you have read, understood, and are bound by, these Terms, as amended from time to
                        time, and that you will comply with the requirements listed herein. These terms expressly supersede any prior written
                        agreements with you. IF YOU DO NOT AGREE TO THESE TERMS, OR COMPLY WITH THE REQUIREMENTS LISTED HERE, PLEASE REFRAIN
                        FROM USING THE OUR SERVICES.</p>
                    </div>
                </div>

            </div>
        </div>

        <div class="insurance-details-desc">

        <p><b>1. SERVICES:</b> Our services include the provision of the platform that enables you to find Employers or Employees, depending on your
        profile and requirement. These are independent third-party Employers or Employees that are made available to you based
        on your location and requirements.</p>

            <div class="row">
                <div class="col-lg-10 col-md-6">
                    <ul class="features-list">
                      
                        <li><i class="fas fa-check"></i>Please note that the Services are intended to be used within the jurisdiction of India. The Employers or Employees
                        provided in this regard are Indian or are otherwise licensed to function in India.</li>
                       <li><i class="fas fa-check"></i>A key part of the Services is to be able to send you notifications through text messages, through the application, or
                    by email. While you may opt out of these services, you agree and acknowledge that this may impact our ability to provide
                    our services to you.</li>
                    </ul>
                </div>
            </div>

        <p><b>2. ACCOUNT:</b> To avail our Services, you will be required to create an account on this platform or our application. For this account,
        you may be required to furnish certain details, including but not limited to personally identifiable information, career
        information and experience details like your resume, and contact information. To create an account, you must be at least
        18 years of age</p>
        
        <div class="row">
            <div class="col-lg-10 col-md-6">
                <ul class="features-list">
        
                    <li><i class="fas fa-check"></i>You warrant that all information furnished by you in connection with your account is and shall remain accurate and
                    true. You also agree to promptly update your details on your account in the event of any change or modification of this
                    information.</li>
                    <li><i class="fas fa-check"></i>You are solely responsible for maintaining the security and confidentiality of your account and agree to immediately
                    notify us of any disclosure or unauthorised use of your account or any other breach of security with respect to your
                    account.</li>
                    <li><i class="fas fa-check"></i>You are solely liable for the activities that take place through your account. We shall not be accountable for any
                    unauthorised access to your account.</li>
                    <li><i class="fas fa-check"></i>You agree to receive communications from us regarding (i) requests for your resume and contact information, (ii)
                    information about us and our promotional offers and services from us or our third-party partners.</li>
                    
                </ul>
            </div>
        </div>

        <p><b>3. CANCELLATION AND REFUND POLICY:</b> You may be entitled to a refund or other remedies for the failure in provision of the services rendered through us.
        However, once the charges and fees are paid, they are final and non-refundable. Subscriptions are also non-refundable,
        but may be cancelled before the renewal cycle. You may elect to cancel your request for a service at any time prior to
        its commencement, although if the transaction is already initiated, you will be charged a cancellation fee. We also
        reserve the right to deduct applicable taxes in respect of such cancellation.</p>
<!--         
        <p><b>4. INDEMNITY:</b> You shall indemnify, defend at our option, and hold us, our affiliates or subsidiaries or
            representatives and agents,
            harmless from and against any claim, demand, lawsuit, judicial proceeding, liabilities, damages and costs, arising
            due
            to or out of your access to the services, violation of these terms or any violation of third-party terms who may
            access
            your account without your authorization.</p> -->


        <p><b>3. CONSUMER CONDUCT:</b> We are strictly against and prohibit discriminatory practices. You are expected to conform to professionally ethical
        practices and refrain from bias based on race, religion, gender, caste, disability, sexual orientation, marital status,
        age, or any other characteristic that may be protected under applicable law. We request you to treat all other users as
        professional with courtesy and respect, provide accurate details and safe environment. Inappropriate behaviour must be
        notified to us immediately and such users will be blocked from using our services further</p>

        <p><b>4. INDEMNITY:</b> You shall indemnify, defend at our option, and hold us, our affiliates or subsidiaries or representatives and agents,
        harmless from and against any claim, demand, lawsuit, judicial proceeding, liabilities, damages and costs, arising due
        to or out of your access to the services, violation of these terms or any violation of third-party terms who may access
        your account without your authorization.</p>
        
        <p><b>5. GENERAL TERMS:</b>
        <div class="row">
            <div class="col-lg-10 col-md-6">
                <ul class="features-list">
        
                    <li><i class="fas fa-check"></i>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance,
                    completeness or suitability of the information and materials found or offered on this website for any particular
                    purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude
                    liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                    <li><i class="fas fa-check"></i>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be
                    liable. It shall be your own responsibility to ensure that any products, services or information available through this
                    website meet your specific requirements Interests.</li>
                    <li><i class="fas fa-check"></i>This website contains material which is owned by or licensed to us. This material includes, but is not limited to,
                    the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the
                    copyright notice, which forms part of these terms and conditions.</li>
                    <li><i class="fas fa-check"></i>All trademarks reproduced in this website which are not the property of, or licensed to, the operator are
                    acknowledged on the website.</li>
                    <li><i class="fas fa-check"></i>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
                    <li><i class="fas fa-check"></i>From time to time this website may also include links to other websites. These links are provided for your
                    convenience to provide further information.</li>
                    <li><i class="fas fa-check"></i>You may not create a link to this website from another website or document without Worker Mandi’s prior written
                    consent.</li>
                    
                </ul>
            </div>
        </div>


        <p><b>6. GOVERNING LAW:</b>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India. Any
        disputes, controversies, or conflicts arising out of these terms shall be resolved by arbitration in New Delhi in
        accordance with the Arbitration and Conciliation Act, 1996 for the time being in force, consisting of a sole arbitrator.
        The parties to arbitration shall keep the proceedings of the arbitration confidential, unless required by another law.
        The decision of the arbitrator shall be final and binding on all parties thereto. Each party to arbitration shall bear
        its own costs.</p>

        <p>We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly
        out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the pre-set limit
        mutually agreed by us with our acquiring bank from time to time.</p>

        <p><b>DISCLAIMER:</b>
        We provide information about employment opportunities and skilled services in the area as per your location and
        requirement. We do not claim any accuracy or currency of the postings. We expect that the posts are updated, managed,
        and taken down as per the requirements and fulfilment of the user. We are only a platform that enable the availability
        of the network around you and make no guarantees of the integrity or validity of each user. Users are encouraged to
        exercise extensive due diligence and precaution before engaging with the services. Your privacy is extremely important
        to us and we have made provisions to explain our use of the information you provide to us in our Privacy Policy. For
        your own safety, we advise you to only provide the information that is asked and no other additional personal
        information that could harm you in any way. We are only an aggregator and shall not be liable for any harm or loss
        caused to you for negligence, misrepresentation, fraud or the lack of practicing caution on your behest.
        </p>

        </div>
    </div>
</section>
