import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./common.service";

@Injectable({
    providedIn: "root",
})
export class GenericCallService {
    _host = "";
    _url = "";
    prm: any = [];

    constructor(
        private _Http: HttpClient,
        private _commonService: CommonService
    ) {
        this._host = _commonService.GetHost();
    }

    postMethodWithoutToken(sp: any, param: any): Observable<any> {
        this._url = this._host + sp;
        try {
            const headers = { "Content-Type": "application/json" };
            return this._Http
                .post<any>(this._url, param, {
                    headers: headers,
                    observe: "response",
                })
                .pipe(
                    map((resp) => {
                        return resp;
                    })
                );
        } catch (error) {
            console.log(error);
        }
    }

    getMethodWithoutToken(sp: string): Observable<any> {
        // Construct the full URL
        this._url = this._host + sp;

        // Define headers
        const headers = { "Content-Type": "application/json" };

        // Perform the HTTP GET request
        return this._Http
            .get<any>(this._url, {
                headers: headers,
                observe: "response", // To observe full response (optional)
            })
            .pipe(
                map((resp) => {
                    // Return the response body or the entire response based on need
                    return resp.body; // Use resp if you want the entire response
                }),
                catchError((error) => {
                    // Handle errors gracefully
                    console.error("Error occurred:", error);
                    return throwError(
                        () =>
                            new Error(
                                "An error occurred while making the GET request."
                            )
                    );
                })
            );
    }
}
