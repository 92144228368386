// import { HomeInsuranceComponent } from "./components/pages/home-insurance/home-insurance.component";
// import { BusinessInsuranceComponent } from "./components/pages/business-insurance/business-insurance.component";
// import { HealthInsuranceComponent } from "./components/pages/health-insurance/health-insurance.component";
// import { TravelInsuranceComponent } from "./components/pages/travel-insurance/travel-insurance.component";
// import { CarInsuranceComponent } from "./components/pages/car-insurance/car-insurance.component";
// import { LifeInsuranceComponent } from "./components/pages/life-insurance/life-insurance.component";
// import { CaseStudyComponent } from "./components/pages/case-study/case-study.component";
// import { CaseStudyDetailsComponent } from "./components/pages/case-study-details/case-study-details.component";
// import { EventsDetailsComponent } from "./components/pages/events-details/events-details.component";
// import { EventsComponent } from "./components/pages/events/events.component";
// import { ServicesOneComponent } from "./components/pages/services-one/services-one.component";
// import { ServicesTwoComponent } from "./components/pages/services-two/services-two.component";
// import { BlogGridComponent } from "./components/pages/blog-grid/blog-grid.component";
// import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";
// import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
// import { TeamComponent } from "./components/pages/team/team.component";
// import { PricingComponent } from "./components/pages/pricing/pricing.component";
// import { TestimonialsComponent } from "./components/pages/testimonials/testimonials.component";
// import { FaqComponent } from "./components/pages/faq/faq.component";
// import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
// import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
// import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
// import { HomeFourComponent } from "./components/pages/home-four/home-four.component";

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutComponent } from "./components/pages/about/about.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { TermConditionsComponent } from "./components/pages/term-conditions/term-conditions.component";
import { WmHomeComponent } from "./components/pages/wm-home/wm-home.component";
import { ViewInvoiceComponent } from "./components/pages/view-invoice/view-invoice.component";
import { SigninComponent } from "./components/pages/auth/signin/signin.component";
import { SignupComponent } from "./components/pages/auth/signup/signup.component";
import { SignupStepOneComponent } from "./components/pages/auth/signup-step-one/signup-step-one.component";
import { UserListComponent } from "./components/pages/user-list/user-list.component";
import { UserProfileComponent } from "./components/pages/user-profile/user-profile.component";
import { AuthGuard } from "./core/_service/auth-guard.service";
import { FranchiseformComponent } from "./components/franchiseform/franchiseform.component";

const routes: Routes = [
    { path: "", component: WmHomeComponent },
    { path: "about", component: AboutComponent },
    { path: "contact", component: ContactComponent },
    { path: "privacy-policy", component: PrivacyPolicyComponent },
    {
        path: "term-condition",
        component: TermConditionsComponent,
    },
    {
        path: "viewInvoice/:id",
        component: ViewInvoiceComponent,
    },
    {
        path: "signUpStep",
        component: SignupStepOneComponent,
    },
    { path: "signup", component: SignupComponent },
    { path: "signin", component: SigninComponent },
    {
        path: "user-list",
        component: UserListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "user-profile",
        component: UserProfileComponent,
        canActivate: [AuthGuard],
    },

    { path: "Sign-Up-First", component: SignupStepOneComponent },
    { path: "franchise-form", component: FranchiseformComponent },
    { path: "**", component: ErrorComponent },

    // { path: "", component: HomeFourComponent },
    // { path: "home-one", component: HomeOneComponent },
    // { path: "home-two", component: HomeTwoComponent },
    // { path: "home-three", component: HomeThreeComponent },
    // { path: "team", component: TeamComponent },
    // { path: "services-one", component: ServicesOneComponent },
    // { path: "services-two", component: ServicesTwoComponent },
    // { path: "home-insurance", component: HomeInsuranceComponent },
    // { path: "business-insurance", component: BusinessInsuranceComponent },
    // { path: "health-insurance", component: HealthInsuranceComponent },
    // { path: "travel-insurance", component: TravelInsuranceComponent },
    // { path: "car-insurance", component: CarInsuranceComponent },
    // { path: "life-insurance", component: LifeInsuranceComponent },
    // { path: "case-study", component: CaseStudyComponent },
    // { path: "case-study-details", component: CaseStudyDetailsComponent },
    // { path: "events", component: EventsComponent },
    // { path: "events-details", component: EventsDetailsComponent },
    // { path: "blog-grid", component: BlogGridComponent },
    // { path: "blog-right-sidebar", component: BlogRightSidebarComponent },
    // { path: "blog-details", component: BlogDetailsComponent },
    // { path: "pricing", component: PricingComponent },
    // { path: "testimonials", component: TestimonialsComponent },
    // { path: "faq", component: FaqComponent },
    // { path: "error", component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
