<app-header-four></app-header-four>

<div class="page-title-area page-title-about">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-area ptb-100 bg-f8f8f8">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-image/about1.jpg" alt="image">
                    <img src="assets/img/about-image/about2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>The Story of Worker Mandi</h2>
                    <p>My name is Prerak Bhatnagar, and I work at Satcap India, a manufacturing company specializing in blankets and bedsheets. One of the challenges we face on a daily basis is the shortage of skilled workers such as tailors and quilting masters. This shortage often forces us to adjust our factory's operating hours to accommodate their availability, sometimes even running operations during nighttime or on Sundays. These adjustments incur significant costs for us.

                        In light of this challenge, I had an idea: why not develop an application that connects skilled and unskilled workers with industries in need of their services? Many workers, including skilled artisans and support staff like peons and housekeeping personnel, are not readily available in the market.
                        
                        Thus, I launched the Worker Mandi Mobile Application. This platform aims to bridge the gap between workers and companies by providing a centralized marketplace where companies can find the skilled and unskilled workers they require for their operations.</p>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text-box">
                        <h3>Our Vision</h3>
                        <p>Our main vision to short out all type of Skilled or unskilled workers problem in the Indian industries</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text-box">
                        <h3>Our Mission</h3>
                        <p>Worker Mandi mission is to empower millions of skilled and unskilled Worker to deliver services at one touch like never
                        experience before.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3 offset-sm-3 col-sm-6">
                    <div class="about-text-box">
                        <h3>Who We Are</h3>
                        <p>Worker Mandi provides a platform that allows skilled or unskilled Worker to connect with users looking for specific category with their location.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="why-choose-us-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="why-choose-us-slides owl-carousel owl-theme">
                    <div class="why-choose-us-image bg1">
                        <img src="assets/img/why-choose-img1.jpg" alt="image">
                    </div>

                    <div class="why-choose-us-image bg2">
                        <img src="assets/img/why-choose-img2.jpg" alt="image">
                    </div>

                    <div class="why-choose-us-image bg3">
                        <img src="assets/img/why-choose-img3.jpg" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <div class="title">
                            <span class="sub-title">Your Benefits</span>
                            <h2>Why Choose Us</h2>
                            <p>Our Web & mobile application is developed with the aim to direct connection daily wages labor & employment provider with
                            help of technology. Because all the big & small companies depend on contractor to procure the daily wages labor/workers.</p>
                        </div>

                        <ul class="features-list">
                            <li>
                                <div class="icon">
                                    <i class="flaticon-like"></i>
                                </div>
                                <span>25 Years Of Experience</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-customer-service"></i>
                                </div>
                                <span>24/7 Support</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-care"></i>
                                </div>
                                <span>Service With Love</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-team"></i>
                                </div>
                                <span>Clients Focused</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-policy"></i>
                                </div>
                                <span>No Policy Fees</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-education"></i>
                                </div>
                                <span>Growing Your Business</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="services-area ptb-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Services</span>
            <h2>Our Solutions</h2>
            <p>Worker Mandi India's only Web & mobile application where both employers and employees are available.you will get any skilled /unskilled daily labor with your nearest location.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-home-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/home-insurance">Home Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/home-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/business-insurance">Business Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-health-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/health-insurance">Health Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/health-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-travel-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/travel-insurance">Travel Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/travel-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-car-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/car-insurance">Car Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/car-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-life-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/life-insurance">Life Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/life-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="team-area ptb-100 pb-70 bg-fafafa">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Agent</span>
            <h2>Meet Our Experts</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img2.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Lee Munroe</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img3.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Calvin Klein</h3>
                    <span>Underwriter</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img4.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Sarah Taylor</h3>
                    <span>Agent</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img1.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Alastair Cook</h3>
                    <span>Risk Manager</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Feedback</span>
            <h2>What Client Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                </div>
                
                <button class="prev-arrow slick-arrow">
                    <i class='flaticon-left-chevron'></i>
                </button>
                
                <button class="next-arrow slick-arrow">
                    <i class='flaticon-right-chevron'></i>
                </button>
            </div>
            
            <div class="client-thumbnails">
                <div>
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img2.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Jonus Nathan</h3>
                            <span>CEO at Envato</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img4.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Sadio Finn</h3>
                            <span>CEO at FlatIcon</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Tom Olivar</h3>
                            <span>CEO at ThemeForest</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        <div class="title">
                            <h3>James Finn</h3>
                            <span>CEO at GitLab</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        <div class="title">
                            <h3>John Lucy</h3>
                            <span>CEO at Linkedin</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Sarah Taylor</h3>
                            <span>CEO at Twitter</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        <div class="title">
                            <h3>James Anderson</h3>
                            <span>CEO at Facebook</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Steven Smith</h3>
                            <span>CEO at EnvyTheme</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="achievements-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="achievements-content">
                    <div class="title">
                        <span class="sub-title">Number</span>
                        <h2>Our Achievements</h2>
                        <p>Worker Mandi start in pilot project first in Ludhiana with them aim to connect 10 lac people to be add within next six
                        month, once solve all the technical issue we will increase the city and make India Top class mobile application to
                        arrange daily wages people.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-flag"></i>
                                <h3><span class="odometer" data-count="65">00</span></h3>
                                <p>Countries</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-group"></i>
                                <h3><span class="odometer" data-count="107">00</span> <span class="sign-icon">m</span></h3>
                                <p>Clients</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-medal"></i>
                                <h3><span class="odometer" data-count="150">00</span></h3>
                                <p>Wining Awards</p>
                            </div>
                        </div>
                    </div>

                    <div class="bg-dot"><img src="assets/img/bg-dot.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="achievements-image-slides owl-carousel owl-theme">
                    <div class="single-achievements-image bg1">
                        <img src="assets/img/achievement1.jpg" alt="image">
                    </div>

                    <div class="single-achievements-image bg2">
                        <img src="assets/img/achievement2.jpg" alt="image">
                    </div>

                    <div class="single-achievements-image bg3">
                        <img src="assets/img/achievement3.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-area">
    <div class="container">
        <div class="partner-title">
            <h2>Our Trusted Partners</h2>
        </div>

        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section> -->